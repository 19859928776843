export const homeSectionData = [
  {
    link: "/service/CCTVSurveillance",
    value: "CCTV Surveillance",
    logo: "fa-solid fa-camera-retro",
    desc: "CCTV Surveillance",
  },
  {
    link: "/service/dataCenterModernization",
    value: "Data Center Modernization",
    logo: "fa-solid fa-server",
    desc: "Data Center Modernization",
  },
  {
    link: "/service/Network&Infrastructure",
    value: "Network & Infrastructure",
    logo: "fa-solid fa-network-wired",
    desc: "Network & Infrastructure",
  },
  {
    link: "/service/cloudTransformation",
    value: "Cloud Transformation",
    logo: "fa-solid fa-cloud",
    desc: "Cloud Transformation",
  },
  {
    link: "/service/softwareDev",
    value: "Software Development",
    logo: "fa-solid fa-laptop",
    desc: "Software Development",
  },
  {
    link: "/service/ItManageService",
    value: "IT Managed Services",
    logo: "fa-solid fa-gear",
    desc: "IT Managed Services",
  },
];

export const homePageFourthSectionData = [
  {
    logo: "images/sublogo1.png",
    title: "End To End Digital Infrastructure",
    desc: "Life Cycle Solutions and Services providers",
  },
  {
    logo: "images/sublogo2.png",
    title: "Delightful Customer Experience",
    desc: "Customers across verticals and regions",
  },
  {
    logo: "images/sublogo3.png",
    title: "Delivering Intended Outcomes",
    desc: "Solutions impacting customers' business objectives",
  },
  {
    logo: "images/sublogo4.png",
    title: "Fulfilling Your Future Relentlessly",
    desc: "Making Customers win every time relentlessly",
  },
];

export const homePageFifthData = [
  {
    logo: "/images/sublogo5.png",
    title: `${Math.abs(new Date().getFullYear() - 2019)}`,
    desc: "Years of History",
  },
  {
    logo: "/images/sublogo6.png",
    title: "30",
    desc: "Our Associates",
  },
  {
    logo: "/images/sublogo7.png",
    title: "15",
    desc: "Global Partners",
  },
  {
    logo: "/images/sublogo8.png",
    title: "100",
    desc: "Clients",
  },
];

export const testimonialData = [
  {
    desc: "Our IT budget has gone down while our IT infrastructure has grown more robust thanks to RIMS from Value Point.",
    title: "Healthcare",
    position: "CTO",
  },
  {
    desc: "Our business has grown with a reliable IT backbone, exceptional domain expertise and round-the-clock support.",
    title: "Education",
    position: "IT Manager",
  },
  {
    desc: "ValuePoint has helped us secure our customers data and compliance with their meticulous services.",
    title: "BFSI",
    position: "IT Manager",
  },
];
