export const contactSecondDivData = [
  {
    title: "Head Office",
    comName: "Value Point Systems Pvt Ltd,",
    address:
      "#66, St. John’s Wood Road,Tavarekere Main Road, Koramangala, Bengaluru, Karnataka 560029, India.",
    phno: "Sales: +91-78999 00121",
    ph2: "Phone: 080-6626 6000",
    email: "reach@valuepointsystems.com",
  },
  {
    title: "Cybersecurity Sales",
    comName: "Value Point Systems Pvt Ltd,",
    address:
      "#102, Brigade Corner, Yediyur Circle, 7th Block, Jayanagar Bengaluru, Karnataka 560082, India.",
    phno: "Sales: +91-78999 00121",
    ph2: "Phone: 080-6626 6000",
    email: "reach@valuepointsystems.com",
  },
  {
    title: "ValuePoint Accelerate",
    comName: "#23, Nadathur Place, Level 1,",
    address:
      "16th Cross, 8th Main Road, 3rd Block, Jayanagar, Bengaluru, Karnataka 560011, India.",
    phno: "Sales: +91-78999 00121",
    ph2: "Phone: 080-6626 6000",
    email: "reach@valuepointsystems.com",
  },
];

export const contactThirdPageData = [
  {
    title: "YANGON",
    comName: "6th Floor, Red Hill Tower, Pyay Road,",
    address: "Sanchaung Township,Yangon,Myanmar",
    phno: "Sales: +95 9775133334 ",
    ph2: "Phone: +95 9775133334",
    email: "sales@younique.com.mm",
  },
  {
    title: "PYAY",
    comName: "No.l57, Beside of CB Bank 2,",
    address: "Bogyoke Aung San Road, Pyay Tsp.",
    phno: "Sales: +95 9775133334 ",
    ph2: "Phone: +95 9775133334 (PYAY)",
    email: "sales@younique.com.mm",
  },
];
