import React, { useEffect } from "react";
import {
  fifthData,
  firstData,
  fourthData,
  secondData,
  sixthData,
  thirdData,
} from "./data";
import "./style.css";

const NetworkTransformation = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Network Transformation";
  }, []);
  return (
    <div>
      <div
        style={{
          width: "100vw",
          minHeight: "400px",
          backgroundImage:
            "url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/360_F_283376458_DygTvGCaBgrKNR3UKK1c8ktdTRwKvd0y.webp)",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "400px",
            backgroundImage: "linear-gradient(30deg, #0003FB 44%, #FFFFFF 90%)",
            opacity: "0.48",
            zIndex: "1",
          }}
        >
          <div
            className="text-white ps-lg-5 ps-1"
            style={{ paddingTop: "9rem" }}
          >
            <h5>SIMPLIFY NETWORK DEPLOYMENTS</h5>
            <h3 className="NetworkTransformationTitle">
              Network & Infrastructure
            </h3>
            <p>
              We reduce your hardware and operating costs with future-ready{" "}
              <br />
              infrastructure.
            </p>
          </div>
        </div>
      </div>
      <div className="secondNetworkTransformation  text-center py-4">
        <h3 className="pb-3 pb-lg-0">Active Solutions Partners</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 contaienr">
          {firstData.map((detail, index) => (
            <div className="col-lg-2 col-5 text-center" key={index}>
              <img style={{ width: "180px" }} src={detail} alt="" />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="thirdNetworkTransformationt text-center py-5">
        <h4 className="mb-4">Network Infra & Integration Partners</h4>
        <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 contaienr">
          {secondData.map((detail, index) => (
            <div className="col-lg-2 col-5 text-center" key={index}>
              <img style={{ width: "130px" }} src={detail} alt="" />
            </div>
          ))}
        </div>
      </div> */}
      <div
        className="fourthNetwrokTransformation py-3 pt-5 text-center"
        style={{ background: "#F0F5F6" }}
      >
        <h1>Network Transformation Services</h1>
        <div className="d-lg-flex justify-content-center align-items-center text-center py-5 container">
          {thirdData.map((detail, index) => (
            <div className=" px-lg-5 px-2 mb-4 mb-lg-0 fourthChildren">
              <div
                className="text-white text-center pt-4 m-auto"
                style={{
                  background: "#1B2B4E",
                  width: "100px",
                  height: "100px",
                  borderRadius: "20px",
                }}
              >
                <i
                  style={{ fontSize: "3rem" }}
                  className={`${detail.logo}`}
                ></i>
              </div>
              <h3 className="py-4">{detail.title}</h3>
              <p>{detail.desc}</p>
              <button
                className="px-3 py-2 rounded border-0 text-white"
                style={{ background: "#1B2B4E" }}
              >
                Check it out
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="fifthNetworkTransformation d-lg-flex justify-content-center align-items-center py-5 container">
        <div>
          <h2 style={{ fontWeight: "bold" }}>
            Our Support Services Lead The Way
          </h2>
          <div className="d-flex justify-content-center align-items-center flex-wrap pt-5">
            {fourthData.map((detail, index) => (
              <div className="col-lg-6 d-flex align-items-center mb-4">
                <div
                  style={{
                    minWidth: "75px",
                    minHeight: "75px",
                    background: "#1B2B4E",
                    borderRadius: "50%",
                  }}
                  className="text-center text-white pt-4 ps-3 me-3"
                >
                  <i
                    className={`${detail.logo} pe-3`}
                    style={{ fontSize: "2rem" }}
                  ></i>
                </div>

                <h5>{detail.title}</h5>
              </div>
            ))}
          </div>
        </div>
        <div className="py-3 col-lg-5 col-12">
          <img
            className="fifthNetworkTransformationImage"
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/it-networking.webp"
            alt=""
          />
        </div>
      </div>
      <div className="sixthNetworkTransformation d-lg-flex justify-content-center align-items-center py-5 px-lg-5 px-2 gap-5">
        <div className="col-lg-5 col-12">
          <img
            className="sixthNetworkTransformationImage"
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/man-using-app-on-smart-phone-to-control-central-he-79H3GA6-682x1024.jpg"
            alt=""
          />
        </div>
        <div className="col-lg-5 col-12 pt-4 pt-lg-0">
          <h6 style={{ color: "#FFA333" }}>NETWORK BENEFITS</h6>
          <h1 style={{ color: "#1B2B4E" }}>
            Transform Your Networks For The Benefits
          </h1>
          <div>
            {fifthData.map((detail, index) => (
              <div
                key={index}
                className="d-flex justify-content-center align-items-center"
              >
                <i className={`${detail.logo} pe-4`}></i>
                <p>{detail.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{ background: "#F0F5F6" }}
        className="seventhNetworkTransformation py-3"
      >
        <h1
          className="text-center py-4 "
          style={{ fontWeigth: "bold", color: "#1B2B4E" }}
        >
          Our Accomplishments
        </h1>
        <div className="d-lg-flex justify-content-center align-items-center gap-4 px-3 text-center">
          <div className="col-lg-4 col-12 justify-content-center d-flex align-items-center">
            <h1 className="pe-3" style={{ fontSize: "5rem", color: "gray" }}>
              100%
            </h1>
            <h4 className="text-start">
              Customer <br /> Satisfaction
            </h4>
          </div>
          <div className="col-lg-4 col-12 justify-content-center d-flex align-items-center">
            <h1 className="pe-3" style={{ fontSize: "5rem", color: "gray" }}>
              50
            </h1>
            <h4 className="text-start">
              Solutions <br /> implemented
            </h4>
          </div>
          <div className="col-lg-4 col-12 justify-content-center d-flex align-items-center">
            <h1 className="pe-3" style={{ fontSize: "5rem", color: "gray" }}>
              100
            </h1>
            <h4 className="text-start">
              Sites <br /> Monitored
            </h4>
          </div>
        </div>
      </div>
      {/* <div className="eightNetworkTransformation py-5">
        <h5 className="text-center">TESTIMONIALS</h5>
        <h2 className="text-center">How ValuePoint Made A Real Difference</h2>
        <div className="d-lg-flex justify-content-center align-items-top px-lg-5 px-2 mx-3  gap-2 py-5">
          {sixthData.map((detail, index) => (
            <div className="d-flex justify-content-center  col-lg-4 col-12">
              <img
                style={{ width: "50px", height: "50px" }}
                src={detail.logo}
                alt=""
                className="me-3"
              />
              <div>
                <p style={{ fontStyle: "italic" }}>{detail.desc}</p>
                <h5
                  style={{ color: "#1B2B4E", fontWeight: "bold" }}
                  className="pb-3"
                >
                  {detail.name}
                </h5>
                <h6 className="mb-0">{detail.position}</h6>
                <h6>{detail.typeof}</h6>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="industriesFixedDiv text-center text-white">
        <h1>Want To Know How We Can Reduce Your Cost With Next Gen Network?</h1>
        <p>
         Younique is helping global businesses on their digital
          transformation journeys: one business at a time.
        </p>

        <button
          style={{ borderRadius: "20px 0px 20px 0px", background: "#1B2B4E" }}
          className="py-3 px-3 border-0 text-white"
        >
          Request a free consultation
        </button>
      </div>
    </div>
  );
};

export default NetworkTransformation;
