import React from "react";
import Navbar from "./component/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./component/Contact/Contact";
import Home from "./component/Home/Home";
import Footer from "./component/Footer/Footer";
import Industries from "./component/Industries/Industries";
import Paternership from "./component/Partnership/Paternership";
import About from "./component/About/About";
import DataCenter from "./component/DataCenter/DataCenter";
import NetworkTransformation from "./component/NetworkTrans/NetworkTransformation";
import CloudTransformaion from "./component/CloudTransformation/CloudTransformaion";
import ITManageService from "./component/ITManageService/ITManageService";
import Customers from "./component/Customer/Customers";
import CCTVSurveillance from "./component/CCTVSurveillance/CCTVSurveillance";
import SoftwareDev from "./component/SoftwareDev/SoftwareDev";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/customer" element={<Customers />} />
        <Route path="/paternership" element={<Paternership />} />

        <Route
          path="/service/dataCenterModernization"
          element={<DataCenter />}
        />
        <Route
          path="/service/Network&Infrastructure"
          element={<NetworkTransformation />}
        />
        <Route
          path="/service/cloudTransformation"
          element={<CloudTransformaion />}
        />
        <Route path="/service/ItManageService" element={<ITManageService />} />
        <Route
          path="/service/CCTVSurveillance"
          element={<CCTVSurveillance />}
        />
        <Route path="/service/softwareDev" element={<SoftwareDev />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
