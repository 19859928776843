export const partnershipFirstData = [
  { logo: "./images/ooredoo.jpg" },
  {
    logo: "./images/trueIDC.png",
  },
  {
    logo: "./images/MIH.jpg",
  },
  {
    logo: "./images/AGBCommunication.png",
  },
  {
    logo: "./images/skyNet.png",
  },
];

export const partnershipSecondData = [
  {
    logo: "./images/EnterpriseCustomers/stopSuperMarket.png",
  },
  {
    logo: "./images/EnterpriseCustomers/MTHConstructionGroup.png",
  },
  {
    logo: "./images/EnterpriseCustomers/shinsung.png",
  },
  {
    logo: "./images/EnterpriseCustomers/ThongThaiTextile.png",
  },
  {
    logo: "./images/EnterpriseCustomers/vintageLuxury.png",
  },
  {
    logo: "./images/EnterpriseCustomers/ngweSaungBeach.png",
  },
  {
    logo: "./images/EnterpriseCustomers/ostelloBello.png",
  },
  {
    logo: "./images/EnterpriseCustomers/southlandGlobal.png",
  },
  {
    logo: "./images/EnterpriseCustomers/superShine.png",
  },
  {
    logo: "./images/EnterpriseCustomers/unitedLiving.png",
  },
  {
    logo: "./images/EnterpriseCustomers/roseHill.png",
  },
  {
    logo: "./images/EnterpriseCustomers/SMAsia.png",
  },
  {
    logo: "./images/ok.png",
  },
  {
    logo: "./images/cityExpress.png",
  },
{
    logo: "./images/busyBees.png",
  },
];

export const partnershipThirdData = [
  {
    logo: "./images/EmbassyCustomers/EmbassyOfDenmark.png",
  },
  {
    logo: "./images/EmbassyCustomers/qatarEmbassy.png",
  },
  {
    logo: "./images/EmbassyCustomers/third.png",
  },
  {
    logo: "./images/EmbassyCustomers/yude.png",
  },
  {
    logo: "./images/EmbassyCustomers/fifth.png",
  },
  {
    logo: "./images/EmbassyCustomers/sixth.png",
  },
  {
    logo: "./images/EmbassyCustomers/seventh.png",
  },
  {
    logo: "./images/EmbassyCustomers/eight.png",
  },
  {
    logo: "./images/EmbassyCustomers/agti.png",
  },
  {
    logo: "./images/EmbassyCustomers/UM2.png",
  },
  {
    logo: "./images/EmbassyCustomers/UM1.png",
  },
];

export const partnershipFourthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Dlink.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/R-and-M.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/commscope.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Belden.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/panduit.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/schneider-1024x512.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/honeywell.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/logitech.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Vertiv.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/netrack.jpg",
  },

  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Anchor-by-panasonic-1024x299.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/legrand.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/hikvision.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/finolex.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/polycom.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/apw.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/matrix.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/essl.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/numeric.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/eaton.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/crestron-1024x553.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/rittal.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/molex.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/ATEN.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/apc.png",
  },
];

export const partnershipFifthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/aws-partner-png.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project-2.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project-5.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project-4.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/ansible-wide-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/terraform-2.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/jenkins-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/horizontal-logo-monochromatic-white-3.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/kubernetes-horizontal-color-3-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/sonarqube-logo-3.jpg",
  },
];

export const partnershipSixthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/hp-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/dell-logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Acer.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/xerox_logo_red-CMYK_tm-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture18.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture16.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture15.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture14.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture12.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture11.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/logitech.png",
  },

  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture8.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture7.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture6.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture10.png",
  },
];

export const partnershipSeventhData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Kaseya-Logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/manage-engine-logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/WEBROOT.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Saphire-Logo-1.png",
  },
];
