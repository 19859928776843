export const firstData = [
  {
    logo: "fa-solid fa-calendar-days",
    desc: "AMC Services",
  },
  {
    logo: "fa-solid fa-user-shield",
    desc: "IT FMS Services",
  },
  {
    logo: "fa-solid fa-server",
    desc: "ITSM Tools",
  },
  {
    logo: "fa-solid fa-network-wired",
    desc: "NOC Services",
  },
  {
    logo: "fa-solid fa-laptop",
    desc: "Hybrid IT Managed",
  },
  {
    logo: "fa-solid fa-suitcase",
    desc: "Professional Services",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    desc: "Master MSP",
  },
];

export const secondData = [
  {
    logo: "fa-solid fa-calendar-days",
    title: "Annual Maintenance Contract",
    desc: ["OEM B2B Support", "Value point Support", "Warranty Upgrade Packs"],
  },
  {
    logo: "fa-solid fa-user-shield",
    title: "IT Facility Management Services",
    desc: ["SLA Based", "Manpower Based", "Cost+ Model", "Staff Augmentation"],
  },
  {
    logo: "fa-solid fa-server",
    title: "ITSM Tools",
    desc: ["Kaseya", "ManageEngine", "Sapphire", "EverestIMS"],
  },
  {
    logo: "fa-solid fa-network-wired",
    title: "Network Operation Center",
    desc: [
      "RIMS:- Help Desk",
      "First Call Resolution",
      "Asset/ Patch Management",
      "End Point Management",
      "Policy & Automation",
      "NOC:- 24/7 Data Center Monitoring & Management",
      "Vendor Coordination",
    ],
  },
  {
    logo: "fa-solid fa-laptop",
    title: "Hybrid IT Managed Services",
    desc: [
      "Optimizing Resources cost",
      "8X5 to 24/7",
      "People to Process Driven",
      "Reactive to Proactive Support",
      "Scalable",
      "Cost $$$ to $",
      "CAPEX to OPEX",
    ],
  },
  {
    logo: "fa-solid fa-laptop",
    title: `Professional Service`,
    desc: [
      "Technology Migrations",
      "O365 Migrations",
      "AD Migrations",
      "Domain Migrations",
      "IT Relocations",
      "GAP Analysis",
      "Consulting",
    ],
  },
];

export const thirdData = [
  {
    title: "ONSITE",
    desc: [
      "Reactive Support",
      "Support Window 8×5",
      "Higher People Dependency",
      "Full-Time Employee",
      "FTE’s Technical Skills Limitation",
      "FIXED Support Model",
      "Cost – $$$",
    ],
  },
  {
    title: "HYBRID",
    desc: [
      "Proactive Support",
      "Support Window 24×7 (NOC)",
      "Tools & Process Driven",
      "Offshore delivery with min onsite FTE’s",
      "Access to Pool of Certified SME’s",
      "SCALABLE Support Model",
      "Cost – $",
    ],
  },
];

export const fourthData = [
  {
    title: "OPTIMIZING RESOURCES COST",
    percent: "70",
  },
  {
    title: "PROCESS DRIVEN",
    percent: "90",
  },
  {
    title: "PROACTIVE SUPPORT",
    percent: "80",
  },
  {
    title: "SCALABLE",
    percent: "100",
  },
];

export const fifthData = [
  {
    number: "55",
    title: "Seat NOC Facility",
  },
  {
    number: "10",
    title: "Domain Experts",
  },
  {
    number: "3",
    title: "Years Experience",
  },
  {
    number: "100",
    title: "Delighted Customers",
  },
];
