import cx from "./images/cx.png";
import veritas from "./images/veritas.png";
import adhua from "./images/adhua.png";
import zkteco from "./images/zkteco.png";
import yeastar from "./images/yeastar.png";
import alarm from "./images/alarm-cyan.svg";
import distribute from "./images/distribute.svg";
import settings from "./images/settings.svg";

export const firstData = [
  {
    logo: `${settings}`,
    title: "Extensibility",
    desc: "CCTV cameras, DVRs, and also power supply are not standard IT devices. While most IT monitoring solutions can handle standards like IT networks, storage systems or IT infrastructure, only a few can easily integrate devices beyond IT. This requires comprehensive options to easily generate individual monitoring.",
  },
  {
    logo: `${distribute}`,
    title: `Monitoring of distributed sites`,
    desc: "All CCTV components are not always located within one closed network. Especially cameras can be spread over multiple sites or even countries. This requires that the monitoring solution offers a simple and affordable option for monitoring distributed locations.",
  },
  {
    logo: `${alarm}`,
    title: "Alerting",
    desc: "Especially when it has to do with security, it’s vital that the monitoring solution supports mobile alerting via SMS or push notification beyond standard methods like email or desktop notification to make sure that the person in charge can be reached at any time, in any place. In most cases, CCTV systems have to be online and working smoothly 24/7.",
  },
];

export const secondData = [
  {
    logo: "fa-solid fa-house-laptop",
    title: "VDI - Virtual Desktop",
    desc: "A technology that is used to host desktop environments on a centralized server on-premises or on cloud",
  },
  {
    logo: "fa-regular fa-hard-drive",
    title: "Software Defined Storage",
    desc: "a storage architecture that decouples storage software from its hardware, giving you greater scalability, flexibility",
  },
  {
    logo: "fa-solid fa-bolt-lightning",
    title: "High Performance Blades",
    desc: "Resource-Saving Architecture with High Performance, Density and Efficiency to serve complex computing needs.",
  },
];

export const thirdData = [
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Safe & Secure",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "24/7/365 Support",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Uptime Guarantee",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Beyond Experienced",
  },
];

export const fourthData = [
  {
    desc: '"Working with Value Point was a breath of fresh air. I enjoy their personalities as well as their style of business. It resonates with the way we do business at Intuitive IT Solutions. With Value Point, we feel like we finally have someone in our corner to help us find the best solutions. I love how Value Point is all about taking care of the client, and how they improve and add more value."',
    name: "Darshan Bhat",
    position: "VP OF TECHNOLOGY, STARTUP",
  },
  {
    desc: '"Value Point is a great provider. They truly understand the technology and operational needs of a law firm. Our firm relies on them to fully manage and support all of our data center infrastructure and they always deliver. Their commitment to support corporate customers like us is second to none. Value Point’s level of service and support always exceeds our expectations, and we couldn’t be happier.!"',
    name: "Aastha Mishra",
    position: "SR. LAWYER",
  },
];

export const fifthData = [`${cx}`, `${adhua}`, `${yeastar}`, `${zkteco}`];
