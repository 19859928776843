import fortinet from "./images/fortinet.png";
import cisco from "./images/cisco.png";
import panasonic from "./images/panasonic.png";
import mikrotik from "./images/mikrotik.png";
import ubiquiti from "./images/ubiquiti.jpg";
import trendnet from "./images/trendnet.png";
import ruijie from "./images/ruijie.png";
import edgecore from "./images/edgecore.png";
import tplink from "./images/tplink.png";
import aruba from "./images/aruba.png";

export const firstData = [
  `${fortinet}`,
  `${cisco}`,
  `${panasonic}`,
  `${mikrotik}`,
  `${ubiquiti}`,
  `${trendnet}`,
  `${ruijie}`,
  `${edgecore}`,
  `${tplink}`,
  `${aruba}`,
];

export const secondData = [
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Dlink.jpg",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/R-and-M.jpg",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/commscope.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Belden.jpg",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/panduit.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/schneider-1024x512.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/honeywell.jpg",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/logitech.jpg",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Vertiv.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/12/netrack.jpg",
];

export const thirdData = [
  {
    logo: "fa-solid fa-network-wired",
    title: "Network Active Solutions",
    desc: "Device endpoint solutions including laptops, desktops, workstations and more",
  },
  {
    logo: "fa-solid fa-toggle-on",
    title: "Network Infra & Integration",
    desc: "Latest products  & devices to keep your workforce connected from any location",
  },
];

export const fourthData = [
  {
    logo: "fa-solid fa-money-bill",
    title: "Lower Hardware And Operating Costs",
  },
  {
    logo: "fa-solid fa-paper-plane",
    title: "Future-Ready Infrastructure",
  },
  {
    logo: "fa-solid fa-toggle-off",
    title: "Easy-To-Manage Centralized Controls",
  },
  {
    logo: "fa-solid fa-laptop",
    title: "Access Anytime, Anywhere",
  },
];

export const fifthData = [
  {
    logo: "fa-solid fa-wifi",
    desc: "Optimize your network’s performance and efficiency—help your business achieve its full potential.",
  },
  {
    logo: "fa-solid fa-wifi",
    desc: "Modernized networks provide your business more stability and reliability than aging legacy systems, making your business more agile and efficient",
  },
  {
    logo: "fa-solid fa-wifi",
    desc: "Take advantage of automation and deliver next-gen services efficiently to your clients and do away with faulty and outdated routine manual tasks",
  },
  {
    logo: "fa-solid fa-wifi",
    desc: "Streamline the deployment and maintenance of your IT infrastructures with a set of automation tools",
  },
  {
    logo: "fa-solid fa-wifi",
    desc: "Eliminate time-consuming queues between consecutive steps in the automation process",
  },
  {
    logo: "fa-solid fa-wifi",
    desc: "Experience significant increases in operational speed and efficiency—do away with downtime and save time, effort, and money",
  },
];

export const sixthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/customer-icon.png",
    desc: '"Value Point has always been there for me with what I need. I can always count on my account manager to provide me with good solutions for hardware I need, when I need it, at a price point that is hard to beat with a lifetime warranty to boot!"',
    name: "AMIT MISHRA",
    position: "IT Director –Healthcare",
    typeof: "Company",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/customer-icon.png",
    desc: '"I’ve worked with numerous IT firms throughout my career and I can say that Value Point is, by far, the best one I have worked with. They do whatever it takes to accomplish what I need them to do, and do so “behind the scenes” as best they can, realizing we need to focus on the day-to-day demands of our business"',
    name: "DEEPIKA REDDY",
    position: "IT Manager, Website Solutions",
    typeof: "Company",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/customer-icon.png",
    desc: '"Value Point sets the bar very high in quality of product, service and pricing. Value Point strives to re-invent themselves to keep pace with the changes and advancements in technology."',
    name: "MANJUNATH VS",
    position: "Network Manager, Educational",
    typeof: "Institution",
  },
];
