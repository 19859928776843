import React, { useEffect } from "react";
import CountUp from "react-countup";
import { firstData, fourthData, secondData, thirdData } from "./data";
import "./style.css";

const CloudTransformaion = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Cloud Transformation";
  }, []);
  return (
    <div>
      <div
        style={{
          width: "100vw",
          height: "588px",
          backgroundImage:
            "url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/bg-wave.jpg)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="d-lg-flex justify-content-center align-items-center gap-3 px-lg-5 px-2 pt-5"
      >
        <div className="col-lg-6 col-12">
          <h6 style={{ color: "#F79E2D" }}>
            AWARD-WINNING CLOUD READY SERVICES
          </h6>
          <h1 className="firstCloudTransformationTitle">
            Accelerate Your Cloud Transformation Journey With Us
          </h1>
          <p>
            Successful cloud migrations begin with informed planning and a cloud{" "}
            <br />
            smart mindset.
          </p>
        </div>
        <div>
          <img
            className="firstCloudTransformationImage"
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/elements-isometric-distributed-cloud-vector-concept-24V2XK7-2020-01-18.png"
            alt=""
          />
        </div>
      </div>
      <div className="secondCloudTrans py-5 text-center">
        <h5>MAIN SERVICES</h5>
        <h1>Our Cloud Offerings</h1>
        <p className="col-lg-5 col-12 m-auto">
          Designing, deploying, and supporting the journey of technology right
          from physical servers to the cloud.
        </p>
        <div className="d-flex justify-content-center align-items-top flex-wrap container py-5">
          {firstData.map((detail, index) => (
            <div className="col-lg-3 col-6 p-lg-4 p-2">
              <div
                className="border rounded m-auto"
                style={{
                  width: "100px",
                  height: "100px",
                  background: "#F79E2D",
                }}
              >
                <i
                  style={{ fontSize: "3rem" }}
                  className={`${detail.logo} text-white pt-3 m-auto`}
                ></i>
              </div>
              <h4 style={{ fontWeight: "bold" }} className="pt-3">
                {detail.title}
              </h4>
              <p>{detail.desc}</p>
            </div>
          ))}
        </div>
        <button
          style={{ background: "#F79E2D" }}
          className="p-2 px-4 rounded border-0 text-white"
        >
          All Services{" "}
          <span>
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
      <div className="thirdCloudTrans text-center pt-5">
        <h6 style={{ color: "#F79E2D" }}>STILL COUNTING</h6>
        <h1
          className="col-lg-6 col-12 m-auto"
          style={{ color: "#263351", fontWeight: "bold", fontSize: "3rem" }}
        >
          We Lead And Support Our Customer’s Cloud
        </h1>
        <div className="d-lg-flex justify-content-center align-items-center gap-5 py-5">
          <div className="col-lg-5 ">
            <img
              className="thirdCloudTransImage"
              src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/cloud-customers.webp"
              alt=""
            />
          </div>
          <div className="col-lg-5 col-12 d-flex justify-content-center flex-wrap gap-3 ">
            {secondData.map((detail, index) => (
              <div className="col-5 ">
                <h1
                  style={{ fontSize: "4rem", color: "#49A1EA" }}
                  className="pt-4"
                >
                  <CountUp
                    start={0}
                    end={detail.number}
                    enableScrollSpy
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />+
                      </div>
                    )}
                  </CountUp>
                </h1>
                <h4 style={{ fontWeight: "bold" }} className=" px-4">
                  {detail.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="fourthCloudTrans">
        <div className="fourthCloudTransSecond">
          <div className="text-center fourthCloudTransThird">
            <h5 style={{ color: "#F79E2D" }}>GET BEST PLANS FOR MORE POWER</h5>
            <h1
              style={{ color: "#263351", fontWeight: "bold", fontSize: "3rem" }}
            >
              One-Click Installs So You Can Build Your Business.
            </h1>
            <button
              style={{ background: "#F79E2D" }}
              className="p-2 px-4 text-white border-0 rounded mt-5"
            >
              Get Free Assessment{" "}
              <span>
                <i class="fa-solid fa-arrow-right"></i>
              </span>
            </button>
          </div>
        </div>

        {/* E3F4FC */}
      </div>
      <div className="fifthCloudTrans text-center py-5">
        <h6 style={{ color: "#F79E2D" }}>SMART CLOUD SERVICES</h6>
        <h1 style={{ color: "#263351", fontWeight: "bold" }}>
          Built To Boost Your Business
        </h1>
        <p>Migrate your applications with business context.</p>
        <div className="d-flex justify-content-center container gap-4 py-4 flex-wrap">
          {thirdData.map((detail, index) => (
            <div
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              key={index}
              className="px-2 border col-lg-3 col-12 py-4"
            >
              <i
                style={{ fontSize: "2.3rem", color: "#49A1EA" }}
                className={`${detail.logo} pb-3`}
              ></i>
              <h3 style={{ fontWeight: "bold" }}>{detail.title}</h3>
              <p style={{ color: "#A08095" }}>{detail.desc}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="sixthCloudTrans d-lg-flex justify-content-center align-items-center gap-5">
        <div className="col-lg-5 col-12 ">
          <img
            style={{ width: "100%" }}
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/image-1_elements-isometric-authentication-method-vector-concept-DNGF657-2019-07-13-882x1024.png"
            alt=""
          />
        </div>
        <div className="col-lg-6 col-12  px-4 py-5">
          <h5 style={{ color: "#F79E2D" }}>PERFORMANCE</h5>
          <h1
            style={{ fontSize: "3rem", fontWeight: "bold", color: "#263351" }}
            className="mb-4"
          >
            Level Up Your Business With These Must-Haves.
          </h1>
          <div className="py-2">
            <h6 style={{ fontWeight: "bold" }}>Cloud Migration Services</h6>
            <div className="border rounded">
              <div
                style={{
                  width: "90%",
                  background: "#F79E2D",
                  fontSize: ".8rem",
                }}
                className=" d-flex justify-content-between px-2 py-1 text-white"
              >
                <span>PLATFORM MIGRATION</span> <span>90%</span>
              </div>
            </div>
          </div>
          <div className="py-2">
            <h6 style={{ fontWeight: "bold" }}>Cloud Optimizttion</h6>
            <div className="border rounded">
              <div
                style={{
                  width: "85%",
                  background: "#F79E2D",
                  fontSize: ".8rem",
                }}
                className=" d-flex justify-content-between px-2 py-1 text-white"
              >
                <span>PLATFORM MIGRATION</span> <span>85%</span>
              </div>
            </div>
          </div>
          <div className="py-2">
            <h6 style={{ fontWeight: "bold" }}>Cloud Managed Services</h6>
            <div className="border rounded">
              <div
                style={{
                  width: "93%",
                  background: "#F79E2D",
                  fontSize: ".8rem",
                }}
                className=" d-flex justify-content-between px-2 py-1 text-white"
              >
                <span>PLATFORM MIGRATION</span> <span>93%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="seventhCloudTrans text-center py-5">
        <h3 style={{ fontWeight: "bold", color: "#263351" }}>
          Top Technology Practices
        </h3>
        <div className="d-flex justify-content-center align-items-center container gap-5 py-3 flex-wrap">
          {fourthData.map((detail, index) => (
            <div className=" col-lg-2 col-5" key={index}>
              <img style={{ width: "100%" }} src={detail} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div
        style={{ width: "100vw", minHeight: "300px", background: "#DDDFE4" }}
        className="eightCloudTrans d-lg-flex justify-content-center align-items-center gap-5"
      >
        <div
          className="col-lg-6 py-3 border"
          style={{ border: "1px solid green" }}
        >
          <h5 style={{ color: "#F79E2D" }}>GET CONNECTED</h5>
          <h1
            style={{ color: "#263351", fontWeight: "bold", fontSize: "3rem" }}
          >
            Let Us Support Your Cloud Journey
          </h1>
          <p>
            We make your cloud journey easy through informed planning and our
            cloud-smart mindset.{" "}
          </p>
        </div>
        <div className="col-lg-4 col-12 pb-3 pb-lg-0 text-center">
          <button
            className="p-2 px-3 rounded border-0 text-white"
            style={{ background: "#F79E2D" }}
          >
            Request a free consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default CloudTransformaion;
