import React, { useEffect } from "react";
import { contactSecondDivData, contactThirdPageData } from "./data";
import "./style.css";

const Contact = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Contact";
  }, []);
  return (
    <div className="contactPage">
      <div className="contactFirstPage pt-5  mt-5 mt-lg-0">
        <h1 className="text-center pt-lg-5 contactLandingText">Contact Us</h1>
      </div>
      <div className="contactFirstPageSecSec d-lg-flex justify-content-center align-items-top gap-3 container  ">
        <div className="col-lg-4 col-12 minWidth400div">
          <h1>Let's Make Something Awesome Together.</h1>
          <div className="smallunderline my-5 mb-4"></div>
          <h5>
            We are ready to lead you to the future of Digital Transformation.
          </h5>
        </div>
        <div style={{ minWidth: "320px" }} className="col-lg-3 col-12 ">
          <div>
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              aria-hidden="true"
              class="fas fa-mobile-alt py-2"
            ></i>
            <h4>Call Our Office</h4>
            <div className="m-0 d-flex">
              <p className="col-2 m-0 ">Sales:</p>
              <span> +95 9775133334 </span>
            </div>
            <div className="m-0 d-flex">
              <p className=" col-2">Info:</p>
              <span> +95 9888030330</span>
            </div>
          </div>
          <div>
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              aria-hidden="true"
              class="fas fa-map-marker-alt py-2"
            ></i>
            <h4>Stop By</h4>
            {/* <p className="m-0">Value Point Systems Pvt. Ltd.,</p> */}
            <p className="m-0">6th Floor, Red Hill Tower, Pyay Road,</p>
            <p className="m-0">Sanchaung Township,Yangon,Myanmar</p>
          </div>
        </div>
        <div className="col-3 ">
          <div>
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              aria-hidden="true"
              class="fab fa-telegram-plane"
            ></i>
            <h4>Email Us</h4>
            <div className="m-0 d-flex">
              <p className="col-2 m-0 ">Sales:</p>
              <span> sales@younique.com.mm </span>
            </div>
            <div className="m-0 d-flex">
              <p className=" col-2">Info:</p>
              <span> info@younique.com.mm</span>
            </div>
          </div>
          <div className="py-5">
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              aria-hidden="true"
              class="fas fa-project-diagram"
            ></i>
            <h4>Connect With Us</h4>
            <ul className="d-flex justify-content-start align-items-center gap-1 p-0">
              <li
                style={{
                  background: "#FF5722",
                  minWidth: "50px",
                  minHeight: "50px",
                }}
                className="list-unstyled "
              >
                <i
                  style={{ fontSize: "1.9rem" }}
                  class="fab fa-linkedin text-white p-2"
                ></i>
              </li>
              <li
                style={{
                  background: "#FF5722",
                  minWidth: "50px",
                  minHeight: "50px",
                }}
                className="list-unstyled "
              >
                <a
                  href="https://www.facebook.com/YouniqueInfoTech"
                  target="_blank"
                >
                  <i
                    style={{ fontSize: "1.9rem" }}
                    class="fab fa-facebook text-white p-2"
                  ></i>
                </a>
              </li>
              {/* <li
                style={{
                  background: "#FF5722",
                  minWidth: "50px",
                  minHeight: "50px",
                }}
                className="list-unstyled "
              >
                <i
                  style={{ fontSize: "1.9rem" }}
                  class="fab fa-twitter text-white p-2"
                ></i>
              </li> */}
              {/* <li
                style={{
                  background: "#FF5722",
                  minWidth: "50px",
                  minHeight: "50px",
                }}
                className="list-unstyled "
              >
                <i
                  style={{ fontSize: "1.9rem" }}
                  class="fab fa-youtube text-white p-2"
                ></i>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="contactFirstPage contactFirstPageSecond">
        <h1
          // style={{ fontSize: "3rem", fontWeight: "bold" }}
          className="text-center pt-lg-5 contactLandingText"
        >
          Our Corporate Offices
        </h1>
      </div>
      <div className="d-lg-flex justify-content-center align-items-center gap-3 container pb-5">
        {contactSecondDivData.map((detail, index) => (
          <div className="col-lg-4 col-12 mb-3 mb-lg-0 border p-3" key={index}>
            <h3 className="text-center pb-1">{detail.title}</h3>
            <div className="underline"></div>
            <div className="d-flex justify-content-center align-items-center gap-3">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-map-marker-alt"
              ></i>
              <div>
                <p className="m-0">{detail.comName}</p>
                <p>{detail.address}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-phone-square"
              ></i>
              <p className="m-0 py-2">{detail.phno}</p>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-phone-square"
              ></i>

              <p className="m-0 py-2">{detail.ph2}</p>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-envelope-open"
              ></i>
              <p className="m-0 py-2">{detail.email}</p>
            </div>
          </div>
        ))}
      </div> */}
      <div className="contactFirstPage contactFirstPageThird">
        <h1
          // style={{ fontSize: "3rem", fontWeight: "bold" }}
          className="text-center pt-lg-5 contactLandingText"
        >
          Our Offices
        </h1>
      </div>
      <div className="d-lg-flex justify-content-center align-items-center gap-3 container pb-5">
        {contactThirdPageData.map((detail, index) => (
          <div className="col-lg-6 col-12 border p-3" key={index}>
            <h3 className="text-center pb-1">{detail.title}</h3>
            <div className="underline"></div>
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-map-marker-alt"
              ></i>
              <div>
                <p className="m-0">{detail.comName}</p>
                <p>{detail.address}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-phone-square"
              ></i>
              <p className="m-0 py-2">{detail.phno}</p>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-phone-square"
              ></i>

              <p className="m-0 py-2">{detail.ph2}</p>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <i
                style={{ color: "#FF5722" }}
                aria-hidden="true"
                class="fas fa-envelope-open"
              ></i>
              <p className="m-0 py-2">{detail.email}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="contactPageWrapper d-lg-flex flex-row-reverse justify-content-center align-items-top gap-2">
        <div className="secondCP col-lg-4 col-12  p-3">
          <div className="border secondCPchild">
            <div className="p-3 py-5">
              <div className="d-flex gap-3 justify-content-center align-items-center pb-4">
                <img
                  src="https://lithohtml.themezaa.com/images/user-01.jpg"
                  alt=""
                  className="rounded-circle"
                />
                <h4>More comfortable talking with us?</h4>
              </div>
              <p>
                Schedule a 15 minute intro call with us. <br /> He'll answer
                your questions and discuss.
              </p>
              <a href="">PICK A SCHEDULE</a>
            </div>
          </div>
        </div>
        <div className="firstCP col-lg-7 col-12 p-3">
          {/* <form onSubmit={(e) => handleSubmit(e)}> */}
          <form>
            <h1 className="pb-3">Contact Form</h1>
            <div className="fistCPForm">
              <input
                type="text"
                name="name"
                id="name"
                className="name"
                placeholder="FULL NAME"
                required
              // value={formValues.name}
              // onChange={handleChange}
              />
              <input
                type="text"
                name="email"
                id="email"
                className="email"
                placeholder="EMAIL ADDRESS"
                required
              // value={formValues.email}
              // onChange={handleChange}
              />
              <input
                type="text"
                name="phone"
                id="phone"
                className="phone"
                placeholder="PHONE NUMBER"
                required
              // value={formValues.phone}
              // onChange={handleChange}
              />
              <textarea
                name="message"
                id="textArea"
                cols="30"
                rows="8"
                placeholder="MESSAGE"
                className="ps-3"
                // value={formValues.message}
                // onChange={handleChange}
                required
              ></textarea>
              <button className="sendMessage">
                {/* <Link
                  to="/emialSuccessful"
                  className="text-black text-decoration-none"
                > */}
                SEND MESSAGE
                {/* </Link> */}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
