import React, { useEffect } from "react";
import { firstData, fourthData, secondData, thirdData } from "./data";
import "./style.css";
import veritas from "./images/veritas.png";

import cx from "./images/cx.png";
import adhua from "./images/adhua.png";
import yeastar from "./images/yeastar.png";
import zkteco from "./images/zkteco.png";

const CCTVSurveillance = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Data Center";
  }, []);
  return (
    <div>
      <div
        style={{ background: "#FFF5E9", borderBottom: "1px solid black" }}
        className="firstDataCenter d-lg-flex justify-content-center align-items-center pt-5 mt-5 gap-4"
      >
        <div className="col-lg-5 col-12 ps-2 ps-lg-0">
          <h1 className="firstDataCenterTitle ">
            CCTV Surveillance | Younique
          </h1>
          <p style={{ fontWeight: "bold" }}>
            Monitor your CCTV system with Younique
          </p>
        </div>
        <div className="col-lg-5 col-12 ">
          <img
            className="firstDataCenterImage"
            src="https://netraclos.com/wp-content/uploads/2022/09/CCTV-Camera-System-2-1-3-956x1024-min.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="text-center pt-4">
        <h1>Identify the right monitoring solution</h1>
        <p className="col-lg-8 m-auto">
          A monitoring solution for your CCTV systems must also meet all
          standard requirements like: Feature set, usability, price performance
          ratio, licensing, implementation effort, technical support. But, in
          this case you should pay special attention to the following aspects:
        </p>
      </div>
      <div className="secondPartOfDataCenter d-lg-flex justify-content-start align-items-top px-lg-5 container gap-3  text-center pt-5 py-3">
        {firstData.map((detail) => (
          <div className="px-3 pt-3 border col-lg-4 col-12 rounded mb-3 mb-lg-0">
            <img
              style={{ width: "100px", height: "100px" }}
              src={detail.logo}
              alt=""
            />
            <h3 style={{ minHeight: "50px" }}>{detail.title}</h3>
            <p>{detail.desc}</p>
          </div>
        ))}
      </div>
      <div className="thirdDataCenter text-center py-5">
        <h3>Top Technology Partners</h3>
        <p className="col-10 m-auto">
          We design data center solutions with redundancy to preserve your data
          and scalability at the forefront. And we use leading technology with
          fully licensed hardware and software with partners such as these
        </p>
        <div className="d-lg-flex justify-content-center align-items-center pt-5">
          <img className="col-lg-2 col-5 pb-3 pb-lg-0 px-4 " src={cx} alt="" />

          <img className="col-lg-2 col-5 pb-3 px-4 " src={adhua} alt="" />
          <img className="col-lg-2 col-5 pb-3 px-4 " src={yeastar} alt="" />
          <img className="col-lg-2 col-5 pb-3 px-4 " src={zkteco} alt="" />
          {/* <img
            className="col-lg-2 col-5 pb-3 px-4 "
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/Veeam_logo.png"
            alt=""
          /> */}
        </div>
      </div>
      {/* <div className="fourthDataCenter text-center py-5">
        <h5>OUR FEATURES</h5>
        <h3>Right Solution For Your Applications & Business Outcomes</h3>
        <div className="d-lg-flex container  py-4 pt-5">
          {secondData.map((detail, index) => (
            <div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "1px solid #4861C0",
                  background: "#4861C0",
                  borderRadius: "50%",
                }}
                className="text-center "
              >
                <i
                  style={{ fontSize: "2rem" }}
                  className={`${detail.logo} pt-3 text-white`}
                ></i>
              </div>
              <h4 className="pt-3 text-start">{detail.title}</h4>
              <p className="text-start">{detail.desc}</p>
            </div>
          ))}
        </div>
        <button
          className="py-2 px-3 rounded text-white border-0"
          style={{ background: "#4861C0" }}
        >
          All Services
        </button>
      </div> */}
      <div className="d-flex justify-content-center text-white align-items-center px-3 flex-wrap gap-3 gap-lg-0">
        <div
          className="col-lg-3 col-5 text-center"
          style={{ background: "#4BADD2" }}
        >
          <h1 style={{ fontWeight: "bold", fontSize: "4rem" }}>100</h1>
          <h5 style={{ fontWeight: "bold" }}>Customers</h5>
        </div>
        <div
          className="col-lg-3 col-5 text-center"
          style={{ background: "#4BADD2" }}
        >
          <h1 style={{ fontWeight: "bold", fontSize: "4rem" }}>99%</h1>
          <h5 style={{ fontWeight: "bold" }}>Server Uptime</h5>
        </div>
        <div
          className="col-lg-3 col-5 text-center"
          style={{ background: "#4BADD2" }}
        >
          <h1 style={{ fontWeight: "bold", fontSize: "4rem" }}>50+</h1>
          <h5 style={{ fontWeight: "bold" }}>Deployments</h5>
        </div>
        <div
          className="col-lg-3 col-5 text-center"
          style={{ background: "#4BADD2" }}
        >
          <h1 style={{ fontWeight: "bold", fontSize: "4rem" }}>3+</h1>
          <h5 style={{ fontWeight: "bold" }}>Data Centers</h5>
        </div>
      </div>
      <div className="fifthDataCenter d-lg-flex justify-content-center align-items-center py-5 gap-5">
        <div className="col-lg-5 col-12">
          <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
            {thirdData.map((detail, index) => (
              <div className="col-5  text-center" key={index}>
                <i
                  style={{ fontSize: "3rem" }}
                  className={`${detail.logo} pb-3`}
                ></i>
                <h4>{detail.title}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-5 col-12 px-2 px-lg-0 py-lg-0 py-3 text-center text-lg-start">
          <h6>WHY CHOOSE US?</h6>
          <h3>Scale Your Modern Data Center</h3>
          <p>
            Modern data centers may be physical facilities, cloud-based (private
            or public), or hybrid infrastructure. Each has advantages and
            disadvantages based on the intended use. Don’t worry, though; we’ll
            help you find the best fit for your organization.
          </p>
        </div>
      </div>
      {/* <div style={{ background: "#6EC1E4" }} className="sixth text-center pt-5">
        <h5 className="text-white">TESTIMONIALS</h5>
        <h3 className="text-white">What Our Customers Say</h3>
        <div className="d-lg-flex justify-content-center align-items-center text-start container gap-5 py-5">
          {fourthData.map((detail, index) => (
            <div
              key={index}
              className="border mb-2 mb-lg-0 p-4 bg-white rounded"
            >
              <p>{detail.desc}</p>
              <div className="d-flex  align-items-center">
                <img
                  style={{ width: "80px" }}
                  className="pe-3"
                  src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/customer-icon.png"
                  alt=""
                />
                <div>
                  <h4>{detail.name}</h4>
                  <h5>{detail.position}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div
        style={{
          background:
            "linear-gradient(90deg,rgba(0,0,0,.8) 10%,rgba(0,0,0,.8)),url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/business-team-meeting.jpg)",
          //   backgroundImage: "url(https://wallpaperaccess.com/full/195636.jpg)",
          backgroundSize: "cover",
        }}
        className="text-white"
      >
        <div className="col-lg-5 p-5">
          <p>Data Center Solutions</p>
          <h3>Design A Data Center Solution That’s Right For You</h3>
          <p>
            Our end-to-end professional services begin with an IT environment
            assessment. We then establish Proof-of-Value through our custom data
            center solution. Next, we develop the supporting architecture and
            deployment plans. Lastly, we launch your data center and keep it
            operational and stable, even when disaster strikes.
          </p>
          <button className="border-0 rounded p-2">
            Request a free consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default CCTVSurveillance;
