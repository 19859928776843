export const cctvData = [
  { logo: "./images/3cx.png" },
  { logo: "./images/adhua.png" },
  { logo: "./images/zkteco.png" },
  { logo: "./images/yeastar.png" },
];

export const partnershipFirstData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Trend-Micro-50px.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Cisco-50px.png",
  },

  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture4.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/palo-alto-50px.png",
  },
  {
    logo: "https://www.f5.com/content/dam/f5-com/global-assets/default-ogimage.png",
  },
];

export const partnershipSecondData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Cisco-50px.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Dell_EMC_logo.svg-1024x170.png",
  },
  {
    logo: "	https://www.valuepointsystems.com/wp-content/uploads/2021/11/vmware.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Veeam_logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Red-Hat-logo-1024x576.png",
  },
  {
    logo: "./images/veritas.png",
  },
];

export const partnershipThirdData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/aruba.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/cisco.png",
  },

  {
    logo: "./images/Panasonic.png",
  },
  {
    logo: "./images/mikroTik.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Fortinet-01-e1557823017338.png",
  },
  {
    logo: "./images/ubiquiti.jpg",
  },
  {
    logo: "./images/trendnet.png",
  },
  {
    logo: "./images/ruijie.png",
  },
  {
    logo: "./images/edgecore.png",
  },
  {
    logo: "./images/tplink.png",
  },
  {
    logo: "./images/aruba.png",
  },
];

export const partnershipFourthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Dlink.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/R-and-M.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/commscope.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Belden.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/panduit.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/schneider-1024x512.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/honeywell.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/logitech.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Vertiv.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/netrack.jpg",
  },

  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/Anchor-by-panasonic-1024x299.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/legrand.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/hikvision.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/finolex.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/polycom.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/apw.jpg",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/matrix.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/essl.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/numeric.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/eaton.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/crestron-1024x553.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/rittal.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/molex.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/ATEN.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/apc.png",
  },
];

export const partnershipFifthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/aws-partner-png.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project-2.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project.png",
  },
];

export const partnershipSixthData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/hp-1.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/dell-logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Acer.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture15.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Picture14.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/12/logitech.png",
  },
];

export const partnershipSeventhData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Kaseya-Logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/manage-engine-logo.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/WEBROOT.png",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Saphire-Logo-1.png",
  },
];
