import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context";
import "./style.css";

const Navbar = () => {
  const { active, setActive, goToTop, openNav, setOpenNav } =
    useGlobalContext();
  const [hover, setHover] = useState(false);

  console.log(hover);

  console.log(window.location.href.split("/")[3]);

  const handleClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleServiceClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleLogoClick = () => {
    localStorage.setItem("curRoute", "HOME");
    setActive("HOME");
    goToTop();
    setOpenNav(false);
    window.reload();
  };

  const clickNavOpne = () => {
    setOpenNav(!openNav);
  };

  return (
    <div>
      <div className="navbar d-flex justify-content-center align-items-center py-2 px-lg-5">
        <div className="col-lg-4  text-info text-start">
          <Link to="/" onClick={handleLogoClick}>
            <img
              style={{ width: "100px" }}
              src="/images/Younique-Logo.png"
              alt="logo"
            />
          </Link>
        </div>
        <div
          className="menuIcon d-lg-none col-7 text-end"
          onClick={clickNavOpne}
        >
          <i
            style={{ fontSize: "1.5rem" }}
            className="fa-sharp fa-solid fa-bars text-black"
          ></i>
        </div>
        {/* className={`col-9 d-lg-flex align-items-center navbarRightDiv ${
            openNav && "activeNav"
          }`} */}
        {/* className="col-lg-6 menulistparent" */}
        <div className={`col-lg-7 menulistparent ${openNav && "activeNavbar"}`}>
          <ul className="d-lg-flex justify-content-around align-items-center mb-0">
            <li className="list-unstyled">
              <Link
                className={`${
                  active === "HOME"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                // className="text-decoration-none text-black"
                to="/"
                onClick={(e) => handleClick(e)}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  active === "ABOUT"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                // className="text-decoration-none text-black"
                to="/about"
                onClick={(e) => handleClick(e)}
              >
                ABOUT
              </Link>
            </li>
            <li
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="dropBtn"
            >
              <span
                className={`${
                  window.location.href.split("/")[3] === "service" &&
                  "activetext"
                } `}
              >
                SERVICES{" "}
                <span>
                  {/* <i class="fa-solid fa-caret-down"></i> */}
                  {hover ? (
                    <i
                      style={{ fontSize: ".7rem" }}
                      class="fa-solid fa-chevron-up border"
                    ></i>
                  ) : (
                    <i
                      style={{ fontSize: ".7rem" }}
                      id="downArrow"
                      class="fa-solid fa-chevron-down downArrow border"
                    ></i>
                  )}
                </span>
              </span>

              <div className="dropDownlist">
                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "CCTV Surveillance"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/CCTVSurveillance"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    CCTV Surveillance
                  </Link>
                </p>
                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "Data Center Modernization"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/dataCenterModernization"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    Data Center Modernization
                  </Link>
                </p>
                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "Network & Infrastructure"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/Network&Infrastructure"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    Network & Infrastructure
                  </Link>
                </p>
                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "Cloud Transformation"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/cloudTransformation"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    Cloud Transformation
                  </Link>
                </p>
                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "Software Development"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/softwareDev"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    Software Development
                  </Link>
                </p>

                <p className="py-1 px-2">
                  <Link
                    className={`${
                      active === "IT Managed Services"
                        ? "text-decoration-none activetext"
                        : "text-decoration-none text-black"
                    }`}
                    to="/service/ItManageService"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    IT Managed Services
                  </Link>
                </p>
              </div>
            </li>
            <li>
              <Link
                className={`${
                  active === "PARTNERS"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                to="/paternership"
                onClick={(e) => handleClick(e)}
              >
                PARTNERS
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  active === "CUSTOMERS"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                to="/customer"
                onClick={(e) => handleClick(e)}
              >
                CUSTOMERS
              </Link>
            </li>
            {/* <li>
              <Link
                className={`${
                  active === "INDUSTRIES"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                to="/industries"
                onClick={(e) => handleClick(e)}
              >
                INDUSTRIES
              </Link>
            </li> */}
            <li>
              <Link
                className={`${
                  active === "CONTACT"
                    ? "text-decoration-none activetext"
                    : "text-decoration-none text-black"
                }`}
                to="/contact"
                onClick={(e) => handleClick(e)}
              >
                CONTACT{" "}
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="col-lg-3 d-none d-lg-block text-end pe-5 ">
          <button
            style={{
              borderRadius: "20px 0px 20px 0px",
              background: "rgb(255,87,34)",
            }}
            className="py-3 px-3 border-0 navbarPhoneBtnHover"
          >
            +91 78999 00121
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
