import React, { useEffect } from "react";
import {
  partnershipFifthData,
  partnershipFirstData,
  partnershipFourthData,
  partnershipSecondData,
  partnershipSeventhData,
  partnershipSixthData,
  partnershipThirdData,
} from "./data";
import "./style.css";

const Customers = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Customers";
  }, []);
  return (
    <div className="partnersRoutePage">
      <div className="partnersRouteLandingPage text-white text-center">
        <h1 className="py-2">Our Customers</h1>
        <p>The people we trust—global technology leaders</p>
      </div>
      <div className="container text-center py-5">
        <h3 style={{ fontWeight: "bold" }} className="pb-3">
          Partners Who Enable World-Class Experiences
        </h3>
        <p>
          We have forged thriving strategic partnerships with the world’s
          leading IT companies (OEMs) such as Microsoft, Hewlett Packard,
          Symantec, CISCO, and Veritas, to bring you the most credible, powerful
          next-generation technology products and solutions that your business
          needs. From hybrid platforms to advanced cyber security capabilities
          and data management technologies, the partner solutions we implement
          are the best in their category and designed to deliver a truly
          transformative experience for your business. When you engage with us,
          we become an extension of a global vision for business success in the
          new era.
        </p>
      </div>
      <div className="text-center">
        <h3 style={{ fontWeight: "bold" }} className="pb-3">
          Telecom Media Customers
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipFirstData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "150px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="py-5 text-center">
        <h3 style={{ fontWeight: "bold" }} className="pb-4">
          Enterprise Customers
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipSecondData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "200px" }} key={index}>
              <img style={{ width: "100%", height: "70px", objectFit: "contain" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Embassy & Public Sectors</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap pt-4 container gap-3">
          {/* {partnershipThirdData.map((detail, index) => (
            <div
              className="p-3 border"
              style={{ maxWidth: "200px", height: "100px" }}
              key={index}
            >
              <img style={{ width: "100px" }} src={detail.logo} alt="" />
            </div>
          ))} */}
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "120%" }}
              src="./images/EmbassyCustomers/EmbassyOfDenmark.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "120%" }}
              src="./images/EmbassyCustomers/qatarEmbassy.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "80%" }}
              src="./images/EmbassyCustomers/third.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "120%" }}
              src="./images/EmbassyCustomers/yude.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "60%" }}
              src="./images/EmbassyCustomers/fifth.jpg"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "100%" }}
              src="./images/EmbassyCustomers/sixth.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "100%" }}
              src="./images/EmbassyCustomers/seventh.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "50%" }}
              src="./images/EmbassyCustomers/eight.png"
              alt=""
            />
          </div>
          <div style={{ width: "150px" }}>
            <img
              style={{ width: "70%" }}
              src="./images/EmbassyCustomers/agti.png"
              alt=""
            />
          </div>
          <div style={{ width: "400px" }}>
            <img
              style={{ width: "100%" }}
              src="./images/EmbassyCustomers/UM2.png"
              alt=""
            />
          </div>
          <div style={{ width: "400px" }}>
            <img
              style={{ width: "100%" }}
              src="./images/EmbassyCustomers/UM1.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>
          Network Infra & Integration Services
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipFourthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "150px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Cloud Transformation</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipFifthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "200px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Digital Workplace</h3>
        <h5 style={{ fontWeight: "bold" }}>International</h5>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipSixthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "180px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
        <div className="pt-5 text-center">
          <h3 style={{ fontWeight: "bold" }}>IT Managed Services</h3>
          <div className="d-flex justify-content-center align-items-center flex-wrap container">
            {partnershipSeventhData.map((detail, index) => (
              <div className="p-3" style={{ maxWidth: "180px" }} key={index}>
                <img style={{ width: "100%" }} src={detail.logo} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Customers;
