import React, { useContext, useEffect, useState } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [active, setActive] = useState(
    localStorage.getItem("curRoute") || "HOME"
  );
  const [openNav, setOpenNav] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <AppContext.Provider
      value={{ active, setActive, goToTop, openNav, setOpenNav }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
