export const aboutThirdData = [
  {
    logo: "fa-regular fa-lightbulb",
    title: "Delivering Scalable Solutions",
    desc: "With about 10 operational branches in 3 countries of the world and a workforce of about 1,200, we have deeply rooted ourselves into the world of digital transformation",
  },
  {
    logo: "fa-solid fa-rocket",
    title: "Top Force In The Industry",
    desc: "Member of Partner Advisory Boards of Technology Partners. Top 5 System Integrator Cyber Security Integrator Top 100 IT companies in India. Engaged with 50+ Global Technology Partners",
  },
  {
    logo: "fa-solid fa-users",
    title: "Workforce Experience",
    desc: "Our workforce boasts of over 300 employees with over 10 years of individual experience in the digital transformation industry",
  },
  {
    logo: "fa-solid fa-briefcase",
    title: "Over 100+ Happy Clients",
    desc: "We can incredibly boast of over 2,000 happy customers amongst Fortune 500, Global & SME's. 80% of our revenue comes from returning customers",
  },
];
