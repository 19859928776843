import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { useEffect } from "react";
import { useState } from "react";

const Footer = () => {
  const { active, setActive, goToTop, openNav, setOpenNav } =
    useGlobalContext();
  const [year, setYear] = useState()

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear)
  }, [])

  const handleClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleServiceClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  return (
    <div className="footer text-white">
      <div className="footerFirstDiv d-lg-flex justify-content-center align-items-center gap-5 py-5 px-2 px-lg-0">
        <div
          style={{
            borderRadius: "20px 0px 20px 0px",
            boxShadow: "white 0px 5px 10px",
            minHeight: "162px",
            background: "#00142F",
          }}
          className="col-lg-3 col-12 mb-4 mb-lg-0 d-flex justify-content-center align-items-top gap-4 pt-5 "
        >
          <i
            style={{ fontSize: "2rem" }}
            aria-hidden="true"
            className="fas fa-envelope"
          ></i>
          <div>
            <p>sales@younique.com.mm </p>
            <p>Drop Us a Line</p>
          </div>
        </div>
        <div
          style={{
            borderRadius: "20px 0px 20px 0px",
            boxShadow: "white 0px 5px 10px",

            minHeight: "162px",
            background: "#FF5722",
          }}
          className="col-lg-3 mb-4 mb-lg-0 d-flex justify-content-center align-items-top gap-4 pt-5 "
        >
          <i
            style={{ fontSize: "2rem" }}
            aria-hidden="true"
            className="fa-solid fa-phone"
          ></i>
          <div>
            <p>(+95) 9-775133334 </p>
            <p>Give Us A Call</p>
          </div>
        </div>
        <div
          style={{
            borderRadius: "20px 0px 20px 0px",
            boxShadow: "white 0px 5px 10px",

            minWidth: "356px",
            background: "#00142F",
          }}
          className="col-lg-3 d-flex justify-content-center align-items-center gap-4 py-3 "
        >
          <i
            style={{ fontSize: "2rem" }}
            aria-hidden="true"
            className="fas fa-map-marker-alt ps-2"
          ></i>
          <div>
            <p>Head Office</p>
            <p>
              6th Floor, Red Hill Tower, Pyay Road, Sanchaung Township,Yangon,Myanmar.
            </p>
          </div>
        </div>
      </div>
      <div className="d-lg-flex justify-content-center align-items-top gap-5 container px-lg-5 px-3">
        <div className=" col-lg-4">
          <h3 style={{ color: "#FF5722" }}>ABOUT US</h3>
          <p>
            Accelerating our clients’ business transformation journeys through
            our competence in Consulting, Integration, Security by delivering
            Next-Gen Digital Infrastructure Solutions.
          </p>
          <div className="d-flex justify-content-start align-items-center gap-4">
            <a href="https://www.facebook.com/YouniqueInfoTech" target="_blank">
              <i
                style={{ fontSize: "2rem", color: "#FF5722" }}
                class="fa-brands fa-facebook"
              ></i>
            </a>
            <a href="https://www.linkedin.com/company/younique-infotech/?viewAsMember=true" target="_blank">
              <i
                style={{ fontSize: "2rem", color: "#FF5722" }}
                class="fa-brands fa-linkedin"
              ></i>
            </a>
          </div>
          <div className="mt-3">
            <p>Second Branch</p>
            <p>
              Pyay Branch No.l57, Beside of CB Bank 2, Bogyoke Aung San Road,
              Pyay Tsp.
            </p>
          </div>
        </div>
        <div className=" col-lg-4 ps-lg-5">
          <h3 style={{ color: "#FF5722" }}>Quick Links</h3>
          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "HOME"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              // className="text-decoration-none text-black"
              to="/"
              onClick={(e) => handleClick(e)}
            >
              HOME
            </Link>
          </li>
          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "ABOUT"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              // className="text-decoration-none text-black"
              to="/about"
              onClick={(e) => handleClick(e)}
            >
              ABOUT
            </Link>
          </li>

          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "PARTNERS"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/paternership"
              onClick={(e) => handleClick(e)}
            >
              PARTNERS
            </Link>
          </li>
          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "CUSTOMERS"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/customer"
              onClick={(e) => handleClick(e)}
            >
              CUSTOMERS
            </Link>
          </li>
          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "INDUSTRIES"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/industries"
              onClick={(e) => handleClick(e)}
            >
              INDUSTRIES
            </Link>
          </li>
          <li className="list-unstyled mb-2">
            <Link
              className={`${active === "CONTACT"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/contact"
              onClick={(e) => handleClick(e)}
            >
              CONTACT{" "}
            </Link>
          </li>
          {/* <li className="list-unstyled py-2">
            <Link to="/">Home</Link>
          </li>
          <li className="list-unstyled py-2">About</li>
          <li className="list-unstyled py-2">Service</li>
          <li className="list-unstyled py-2">Partners</li>
          <li className="list-unstyled py-2">Industries</li>
          <li className="list-unstyled py-2">Contact</li> */}
        </div>
        <div className=" col-lg-4 pb-5">
          <h3 style={{ color: "#FF5722" }}>Services</h3>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "CCTV Surveillance"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/CCTVSurveillance"
              onClick={(e) => handleServiceClick(e)}
            >
              CCTV Surveillance
            </Link>
          </li>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "Data Center Modernization"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/dataCenterModernization"
              onClick={(e) => handleServiceClick(e)}
            >
              Data Center Modernization
            </Link>
          </li>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "Network & Infrastructure"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/Network&Infrastructure"
              onClick={(e) => handleServiceClick(e)}
            >
              Network & Infrastructure
            </Link>
          </li>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "Cloud Transformation"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/cloudTransformation"
              onClick={(e) => handleServiceClick(e)}
            >
              Cloud Transformation
            </Link>
          </li>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "Software Development"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/softwareDev"
              onClick={(e) => handleServiceClick(e)}
            >
              Software Development
            </Link>
          </li>
          <li className="list-unstyled py-2">
            <Link
              className={`${active === "IT Managed Services"
                ? "text-decoration-none activetext"
                : "text-decoration-none text-white"
                }`}
              to="/service/ItManageService"
              onClick={(e) => handleServiceClick(e)}
            >
              IT Managed Services
            </Link>
          </li>
        </div>
      </div>
      <div
        style={{
          background: "#00142F",
          boxShadow: "white 0px 5px 15px",
        }}
        className="py-3 text-center"
      >
        <h6>© COPYRIGHT {year} ALL RIGHTS RESERVED</h6>
      </div>
    </div>
  );
};

export default Footer;
