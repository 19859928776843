export const firstData = [
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Cloud-server-3A96CS-1.png",
    title: "Private Cloud",
    desc: "A dedicated server is one that’s exclusively yours and tailored specifically for your requirements",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Data-center-3A96CS.png",
    title: `Pay As You Use`,
    desc: "We manage your servers, including the support needed for remote office working, within our SLA",
  },
  {
    logo: "https://www.valuepointsystems.com/wp-content/uploads/2021/11/Domain-registration-3A96CS.png",
    title: "Hyper Converged Infrastructure",
    desc: "A modern solution that is smarter, simpler, smaller – and more efficient than ever before!",
  },
];

export const secondData = [
  {
    logo: "fa-solid fa-house-laptop",
    title: "VDI - Virtual Desktop",
    desc: "A technology that is used to host desktop environments on a centralized server on-premises or on cloud",
  },
  {
    logo: "fa-regular fa-hard-drive",
    title: "Software Defined Storage",
    desc: "a storage architecture that decouples storage software from its hardware, giving you greater scalability, flexibility",
  },
  {
    logo: "fa-solid fa-bolt-lightning",
    title: "High Performance Blades",
    desc: "Resource-Saving Architecture with High Performance, Density and Efficiency to serve complex computing needs.",
  },
];

export const thirdData = [
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Safe & Secure",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "24/7/365 Support",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Uptime Guarantee",
  },
  {
    logo: "fa-solid fa-clock-rotate-left",
    title: "Beyond Experienced",
  },
];

export const fourthData = [
  {
    desc: '"Working with Value Point was a breath of fresh air. I enjoy their personalities as well as their style of business. It resonates with the way we do business at Intuitive IT Solutions. With Value Point, we feel like we finally have someone in our corner to help us find the best solutions. I love how Value Point is all about taking care of the client, and how they improve and add more value."',
    name: "Darshan Bhat",
    position: "VP OF TECHNOLOGY, STARTUP",
  },
  {
    desc: '"Value Point is a great provider. They truly understand the technology and operational needs of a law firm. Our firm relies on them to fully manage and support all of our data center infrastructure and they always deliver. Their commitment to support corporate customers like us is second to none. Value Point’s level of service and support always exceeds our expectations, and we couldn’t be happier.!"',
    name: "Aastha Mishra",
    position: "SR. LAWYER",
  },
];
