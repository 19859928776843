import React, { useEffect } from "react";

const SoftwareDev = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Software Development";
  }, []);
  return (
    <div>
      <div
        style={{ background: "#00B6C4", Bottom: "1px solid black" }}
        className="firstDataCenter d-lg-flex justify-content-center text-white align-items-center pt-5 mt-5 gap-4"
      >
        <div className="col-lg-5 col-12 ps-2 ps-lg-0">
          <h1 className="firstDataCenterTitle ">
            Software Development | Younique
          </h1>
          <p style={{ fontWeight: "bold" }}>
            We design and modernize data centers to meet your needs and balance
            cost with efficiency.
          </p>
        </div>
        <div className="col-lg-5 col-12 ">
          <img
            className="firstDataCenterImage"
            src="https://prd.thedigitalprojectmanager.com/wp-content/uploads/sites/4/2022/05/software-development-infographic-01-1-1024x1024.png"
            alt=""
          />
        </div>
      </div>
      <div className="container text-center py-5">
        <h2 style={{ color: "#00B6C4" }} className="pb-3">
          Software Development
        </h2>
        <p>
          A software development company with 33 years of business excellence,
          we can develop reliable, scalable and secure software solutions for
          any OS, browser and device. We bring together deep industry expertise
          and the latest IT advancements to deliver custom solutions and
          products that perfectly fit the needs and behavior of their users.
        </p>
        <ul className="d-lg-flex flex-wrap justify-content-center gap-4 pt-4 px-0">
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Software consulting
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Custom software development
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Software development outsourcing
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Software product development
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Team augmentation
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Cloud application development
          </li>
          <li className="col-lg-3 col-12 mb-2 mb-lg-0  list-unstyled p-2 border">
            Legacy software modernization
          </li>
        </ul>
      </div>
      <div className="d-lg-flex px-2 px-lg-0 justify-content-center align-itmes-center pt-4">
        <div className="col-lg-5 col-12">
          <h2 style={{ fontWeight: "bold" }}>
            Every Part of Your IT Ecosystem. Taken Care Of.
          </h2>
          <p className="py-2">
            Comprehensive care of your cloud or on-premises infrastructure and
            applications:
          </p>
          <ul className="d-flex justify-content-start align-items-center flex-wrap gap-3">
            <li className="col-5">Consulting</li>
            <li className="col-5">Security</li>
            <li className="col-5">Implementation</li>
            <li className="col-5">Help desk services</li>
            <li className="col-5">Management and support</li>
            <li className="col-5">Migration</li>
          </ul>
          <button className="rounded mt-3 ms-2">
            LEARN MORE{" "}
            <span>
              <i class="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        </div>
        <div className="col-lg-5 col-12 pt-4 pt-lg-0 ">
          <div className="text-center">
            <img
              style={{ width: "100%" }}
              src="https://www.scnsoft.com/---home-page-illustrations/image-thumb__22108__home_Image-text/managed-it-services~-~media--257a1785--query.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div style={{ background: "#0B3155" }} className="py-5 text-white mt-4">
        <div className="ps-4">
          <h3>Solutions We Deliver</h3>
          <p>
            We IT-enable all kinds of B2B, B2C interactions and internal
            operations.
          </p>
        </div>

        <ul className="d-flex justify-content-start align-items-center ms-3 p-0 m-0 px-2 gap-2 flex-wrap">
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            ERP Solutions
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Human Resource Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Customer Relationship Management
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Camp & Mess Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Hospital Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Document Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Help Desk & Complaint System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Asset Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Restaurant Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Hotel Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            E-commerce Solutions
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            School Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Library Management System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            E-Learning System
          </li>
          <li
            style={{ minWidth: "320px" }}
            className=" list-unstyled border p-3"
          >
            Point of Sales for Restaurant
          </li>
        </ul>
      </div>
      <div className="text-start container py-5">
        <h2 className="text-center">Technologies and Platforms We Work With</h2>
        <div>
          <h3>Web</h3>
          <p className="m-0">BACK END</p>
          <div className="d-flex flex-wrap justify-content-start align-items-center gap-lg-2 gap-1">
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/net.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                className="px-2"
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/java-logo-vertical.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/python_1.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/node-js-logo-small.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/php-logo-small.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/back-end/c-plus-plus.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="	https://www.scnsoft.com/technologies-logos/back-end/go-logo-blue-small.svg"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 pt-4">FRONT END</p>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-lg-2 gap-1">
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="	https://www.scnsoft.com/technologies-logos/front-end/html.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                className="px-2"
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/css.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/js.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/angular-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/react-js-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/meteor.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/vuejs-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/front-end/ember-logo.svg"
                alt=""
              />
            </div>
          </div>
          <h3 className="m-0 pt-4">Mobile</h3>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-lg-2 gap-1">
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/ios-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                className="px-2"
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/android-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/xamarin-logo-vertical.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/cordova-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/pwa-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/react-native-logo.svg"
                alt=""
              />
            </div>
            <div className="col-lg-1 col-3 border text-center py-2">
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://www.scnsoft.com/technologies-logos/mobile/flutter-logo.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="eighthITManageService">
        <div className="industriesFixedDiv text-center text-white">
          <h1>Want An Expert Software Developer For Your Organization?</h1>
          <p>
            Younique Systems is helping global businesses on their digital
            transformation journeys: one business at a time.
          </p>

          <button
            style={{ borderRadius: "20px 0px 20px 0px", background: "#1B2B4E" }}
            className="py-3 px-3 border-0 text-white"
          >
            Request a free consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDev;
