import React, { useEffect } from "react";
import { industriesFirstData } from "./data";
import "./style.css";

const Industries = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Industries";
  }, []);
  return (
    <div className="industriesRoutePage">
      <div className="industriesLanding text-center text-white">
        <h2>MAKING THE DIFFERENCE ACROSS INDUSTRY DOMAINS</h2>
      </div>
      <div className="container py-5">
        <p>
          We have all the capabilities to help accelerate your business
          transformation and propel you to your goals faster and more
          efficiently. Our strength lies in our vast digital transformation
          experience from having extensively worked with leading companies in
          domains such as Healthcare, Retail, Manufacturing, BFSI, and even
          Government Parastatals. The last few years have seen an exponential
          increase in the demand for digital transformation engagement from a
          cross-section of industries, and we have had the opportunity to work
          with some amazing clients in different domains. No matter where you
          are on your digital journey, reach out to us and let us help you get
          there faster.
        </p>
      </div>
      <div className="d-lg-flex justify-content-center align-items-center gap-4 pb-4 flex-wrap px-3 px-lg-0">
        {industriesFirstData.map((detail, index) => (
          <div
            style={{ borderRadius: "20px 0px" }}
            key={index}
            className="col-lg-5 col-12 border mb-3 mb-lg-0 p-5"
          >
            <img src={detail.logo} alt="" />
            <h3 className="py-2">{detail.title}</h3>
            <p>{detail.desc}</p>
          </div>
        ))}
      </div>
      <div className="industriesFixedDiv text-center text-white">
        <h1>
          Want To Know How Your Business Can Benefit From Our Deliverables?
        </h1>
        <p>
          Younique is helping global businesses on their digital
          transformation journeys: one business at a time.
        </p>

        <button
          style={{ borderRadius: "20px 0px 20px 0px", background: "#FF5722" }}
          className="py-3 px-3 border-0 text-white"
        >
          Contact Us Today
        </button>
      </div>
    </div>
  );
};

export default Industries;
