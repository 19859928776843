import React, { useEffect } from "react";
import {
  cctvData,
  partnershipFifthData,
  partnershipFirstData,
  partnershipFourthData,
  partnershipSecondData,
  partnershipSeventhData,
  partnershipSixthData,
  partnershipThirdData,
} from "./data";
import "./style.css";

const Paternership = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Paterners";
  }, []);
  return (
    <div className="partnersRoutePage">
      <div className="partnersRouteLandingPage text-white text-center">
        <h1 className="py-2">Our Partners</h1>
        <p>The people we trust—global technology leaders</p>
      </div>
      <div className="container text-center py-5">
        <h3 style={{ fontWeight: "bold" }} className="pb-3">
          Partners Who Enable World-Class Experiences
        </h3>
        <p>
          We have forged thriving strategic partnerships with the world’s
          leading IT companies (OEMs) such as Microsoft, Hewlett Packard,
          Symantec, CISCO, and Veritas, to bring you the most credible, powerful
          next-generation technology products and solutions that your business
          needs. From hybrid platforms to advanced cyber security capabilities
          and data management technologies, the partner solutions we implement
          are the best in their category and designed to deliver a truly
          transformative experience for your business. When you engage with us,
          we become an extension of a global vision for business success in the
          new era.
        </p>
      </div>
      <div className="text-center">
        <h3 style={{ fontWeight: "bold" }} className="pb-3">
          CCTV Surveillance
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {cctvData.map((detail, index) => (
            <div className="p-3 mb-3" style={{ maxWidth: "150px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="text-center py-3">
        <h3 style={{ fontWeight: "bold" }} className="pb-3 pt-4">
          Cyber Security
        </h3>
        <div className="d-flex justify-content-center align-items-center pb-3 pt-2 flex-wrap container border-bottom">
          {partnershipFirstData.map((detail, index) => (
            <div
              className="p-3 f5picture"
              style={{ maxWidth: "150px" }}
              key={index}
            >
              <img
                style={{ width: "100%", maxHeight: "40px" }}
                src={detail.logo}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
      <div className="py-5 text-center">
        <h3 style={{ fontWeight: "bold" }} className="pb-4">
          Data Center Modernization
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipSecondData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "200px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Network Transformation</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipThirdData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "200px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>
          Network Infra & Integration Services
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipFourthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "150px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div> */}
      <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Cloud Transformation</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipFifthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "200px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="pb-5 text-center">
        <h3 style={{ fontWeight: "bold" }}>Digital Workplace</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap container border-bottom">
          {partnershipSixthData.map((detail, index) => (
            <div className="p-3" style={{ maxWidth: "180px" }} key={index}>
              <img style={{ width: "100%" }} src={detail.logo} alt="" />
            </div>
          ))}
        </div>
        <div className="pt-5 text-center">
          <h3 style={{ fontWeight: "bold" }}>IT Managed Services</h3>
          <div className="d-flex justify-content-center align-items-center flex-wrap container">
            {partnershipSeventhData.map((detail, index) => (
              <div className="p-3" style={{ maxWidth: "180px" }} key={index}>
                <img style={{ width: "100%" }} src={detail.logo} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paternership;
