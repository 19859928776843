import React, { useEffect } from "react";
import "./style.css";
import {
  fifthData,
  firstData,
  fourthData,
  secondData,
  thirdData,
} from "./data";
import CountUp from "react-countup";

import image1 from "./images/image1.jpg";
import image2 from "./images/image2.png";
import image3 from "./images/image3.jpg";
import image4 from "./images/image4.jpg";

const ITManageService = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | IT Manage Service";
  }, []);
  return (
    <div>
      <div
        className="firstITManageService col-6 ps-lg-5 "
        style={{
          width: "100vw",
          height: "500px",
          backgroundImage:
            "url(https://img.freepik.com/free-vector/grey-white-abstract-technology-background-hi-tech-digital-connect-communication-high-technology_262356-448.jpg?w=2000)",
          paddingTop: "10rem",
        }}
      >
        <h1 className="ms-lg-5" style={{ fontSize: "4rem" }}>
          IT Managed Services
        </h1>
        <p className="ms-lg-5">
          We focus on your IT operations so that you can focus on the rest of
          your business.
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-center align-items-center text-center  text-black ITManageSecondPart pb-lg-5 gap-2 gap-lg-0">
        {firstData.map((detail, index) => (
          <div
            className="border ITManagePartChild bg-white homeSecionSecondPartChildHover mb-5 mb-lg-0"
            key={index}
          >
            <div className="ITManageIconParent">
              <i
                id="ITManageIcons"
                aria-hidden="true"
                className={detail.logo}
              />
            </div>

            <h5 className="pt-4">{detail.desc}</h5>
          </div>
        ))}
      </div>
      <div className="thirdITManageService py-5">
        <h3 className="text-center pb-3">Our Services</h3>
        <div className="d-lg-flex justify-content-center flex-wrap px-5 gap-4">
          {secondData.map((detail, index) => (
            <div
              style={{ border: "2px solid #FF5722" }}
              key={index}
              className=" col-lg-3 col-12 mb-2 mb-lg-0 text-center p-3"
            >
              <i
                aria-hidden="true"
                style={{ fontSize: "2.2rem", color: "#FF5722" }}
                className={`${detail.logo} pb-3`}
              />

              <h4 style={{ minHeight: "60px" }} className="text-center">
                {detail.title}
              </h4>
              <p className="text-start">
                {detail.desc.map((each) => (
                  <li>{each}</li>
                ))}
              </p>
            </div>
          ))}
        </div>
        <div className="text-center">
          <button
            style={{ background: "#FF5722" }}
            className="p-2 px-3 rounded text-white border-0 mt-5"
          >
            ALL SERVICE
          </button>
        </div>
      </div>
      <div
        className="fourthITManageService py-4"
        style={{ background: "#E5EAEF" }}
      >
        <h3 className="text-center py-3 pb-5">
          Transform To Hybrid Managed Services
        </h3>
        <div className="d-lg-flex justify-content-center  gap-5">
          {thirdData.map((detail, index) => (
            <div
              style={{ border: "2px solid #FF5722" }}
              className="col-lg-4 col-12 mb-2 mb-lg-0  text-center p-4 rounded"
              key={index}
            >
              <button
                style={{ background: "#FF5722" }}
                className="p-2 px-3 border-0 text-white rounded mb-4"
              >
                {detail.title}
              </button>
              <p style={{ fontSize: "1.1rem" }} className="text-start">
                {detail.desc.map((each) => (
                  <li>{each}</li>
                ))}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="fifthITManageService d-lg-flex justify-content-center align-items-center">
        <div className="col-lg-5 col-12">
          <img
            style={{ width: "100%" }}
            src="https://www.valuepointsystems.com/wp-content/uploads/2022/01/Hybrid-IT.png"
            alt=""
          />
        </div>
        <div className="col-lg-5 col-12 px-2 px-lg-0">
          <h3>We Lead The Global Managed Service Provider Industry</h3>
          <p>
            We provide remote monitoring and management of your environment with
            an onsite team of certified IT engineers managing critical tasks.
          </p>
          <div className="d-flex justify-content-start align-items-center">
            <i class="fa-solid fa-check pe-2"></i>
            <p className="m-0">Cost $$$ to $</p>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <i class="fa-solid fa-check pe-2"></i>
            <p className="m-0">CAPEX to OPEX</p>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <i class="fa-solid fa-check pe-2"></i>
            <p className="m-0">8x5 to 24x7</p>
          </div>
          <br />
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4">
            {fourthData.map((detail, index) => (
              <div className="border col-5 p-2" key={index}>
                <p
                  className="m-0 mb-2 text-capitalize"
                  style={{ fontSize: "1rem" }}
                >
                  {detail.title.toLowerCase()}
                </p>
                <div
                  style={{
                    border: "1px solid #FF5722",
                    background: "#fff",
                    height: "25px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: `${detail.percent}%`,
                      height: "100%",
                      background: "#FF5722",
                    }}
                  >
                    <p className="h-100 m-0" style={{ float: "right" }}>
                      {detail.percent}%
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="sixthITManageService text-center py-3">
        <h3>Maximize Your Infrastucture Support</h3>
        <div className="d-lg-flex jusityf-content-center align-items-center  px-5">
          {fifthData.map((detail, index) => (
            <div className="col-lg-3 col-12" key={index}>
              <h1 style={{ fontSize: "5rem", color: "#FF5722" }}>
                <CountUp
                  start={0}
                  end={detail.number}
                  enableScrollSpy
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />+
                    </div>
                  )}
                </CountUp>
              </h1>
              <h5>{detail.title}</h5>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="seventhITManageService text-center py-4">
        <h3>Top Technology Partners</h3>
        <div className="d-lg-flex justify-content-center align-items-center px-5">
          <div className="col-lg-3 col-12 px-3 mb-5 mb-lg-0">
            <img
              style={{ width: "150px" }}
              src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/Kaseya-Logo.png"
              alt=""
            />
          </div>
          <div className="col-lg-3 col-12 mb-5 mb-lg-0">
            <img
              style={{ width: "200px" }}
              src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/Saphire-Logo-1.png"
              alt=""
            />
          </div>
          <div className="col-lg-3 col-12 mb-5 mb-lg-0">
            <img
              style={{ width: "200px" }}
              src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/manage-engine-logo.png"
              alt=""
            />
          </div>
          <div className="col-lg-3 col-12 mb-5 mb-lg-0">
            <img
              style={{ width: "200px" }}
              src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/WEBROOT.png"
              alt=""
            />
          </div>
        </div>
      </div> */}
      <div className="seventhITManageService text-center py-4">
        <h3>IT Manage Service</h3>
        <div className="d-lg-flex justify-content-center align-items-top px-5 pt-5">
          <div className="col-lg-3 col-12 px-3  mb-5 mb-lg-0">
            <div style={{ minHeight: "210px" }}>
              <img
                style={{ width: "150px" }}
                className="pt-3"
                src={image1}
                alt=""
              />
            </div>
            <h5 className="">Datacenter Maintenance Service</h5>
          </div>
          <div className="col-lg-3 col-12 mb-5  mb-lg-0">
            <div style={{ minHeight: "210px" }}>
              <img style={{ width: "200px" }} src={image2} alt="" />
            </div>
            <h5 className="">Network Infra Maintenance Service</h5>
          </div>
          <div className="col-lg-3 col-12 mb-5  mb-lg-0">
            <div style={{ minHeight: "210px" }}>
              <img style={{ width: "200px" }} src={image3} alt="" />
            </div>
            <h5 className="">
              CCTV Maintenance <br /> Services
            </h5>
          </div>
          <div className="col-lg-3 col-12 mb-5  mb-lg-0">
            <div style={{ minHeight: "210px" }}>
              <img
                className="pt-4"
                style={{ width: "200px" }}
                src={image4}
                alt=""
              />
            </div>
            <h5 className="">PC / Laptop and office outsourcing Servie</h5>
          </div>
        </div>
      </div>
      <div className="eighthITManageService">
        <div className="industriesFixedDiv text-center text-white">
          <h1>Want An Expert IT Managed Services For Your Organization?</h1>
          <p>
            Younique Systems is helping global businesses on their digital
            transformation journeys: one business at a time.
          </p>

          <button
            style={{ borderRadius: "20px 0px 20px 0px", background: "#1B2B4E" }}
            className="py-3 px-3 border-0 text-white"
          >
            Request a free consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default ITManageService;
