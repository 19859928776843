import React, { useEffect } from "react";
import { aboutThirdData } from "./data";
import "./style.css";

const About = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | About";
  }, []);
  return (
    <div>
      <div className="landingAboutPage mt-5">
        <div style={{ paddingTop: "9rem" }} className="text-center text-white">
          <h3>About Younique</h3>
          <h6>
            Your first-choice digital transformation catalyst for expert digital
            solutions.
          </h6>
        </div>
      </div>
      <div className="secondPrtAbout">
        <div className="d-lg-flex justify-content-center align-items-center">
          <div className="col-lg-6 col-12 ps-lg-4 ps-1">
            <h3 className="text-center mb-5">
              About Our Company Beyond Technology
            </h3>
            <div className="d-flex">
              <i
                class="fa-solid fa-bullseye px-3"
                style={{ fontSize: "3rem", color: "#FF5722" }}
              ></i>
              <div>
                <h3>Who We Are</h3>
                <p>
                  Younique info-tech Company is a system integrator and was
                  founded in 2019 in Myanmar. Our belief in constant
                  technological evolution in this ever-changing digital
                  landscape and our long lasting relationships with our
                  customers makes us a trusted and preferred business partner in
                  many of our clients’ digital transformation.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <i
                class="fa-solid fa-atom px-3"
                style={{ fontSize: "3rem", color: "#FF5722" }}
              ></i>
              <div>
                <h3>What We Do</h3>
                <p>
                  We offer an integrated collection of world-class Information &
                  Communication Technology (ICT) Solution & services, System
                  Integration, Software and Hardware products,DataCenter
                  consultancy & infrastructure, VMS Solution, IT Solutions,
                  System Integration and building reliable Network
                  Infrastructures.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 col-0 d-none d-lg-block"
            style={{
              height: "500px",
              backgroundSize: "cover",
              backgroundImage:
                "url( https://www.valuepointsystems.com/wp-content/uploads/2021/11/bg-F7QK79-e1613419388315.jpg)",
            }}
          ></div>
        </div>
      </div>
      <div
        style={{ background: "#ECECEC" }}
        className="text-center border py-5"
      >
        <h5 style={{ color: "#FF5722" }}>Why Choose Us?</h5>
        <h3>High-End Digital Transformation Results</h3>
        <p className="col-lg-8 col-12 m-auto">
          There are several reasons why ValuePoint has the best access to
          high-end digital transformation results. But we added a few below
        </p>
        <div className="d-lg-flex flex-wrap justify-content-center align-items-center gap-3 py-4">
          {aboutThirdData.map((detail, index) => (
            <div
              style={{ height: "60px" }}
              className="col-lg-5 col-12 text-start d-flex border pt-1 bg-white rounded"
              key={index}
            >
              <i
                style={{
                  fontSize: "2.5rem",
                  minWidth: "60px",
                  color: "#FF5722",
                }}
                className={`${detail.logo} text-center pt-1 pb-1`}
              ></i>
              <div className="ps-3 pe-2">
                <h3>{detail.title}</h3>
                {/* <p>{detail.desc}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="backgroundFixedDiv text-center">
        <div className="backgroundFixedDivUpperLayer ">
          <h3>
            Want To Know How Your Business Can Benefit From Our Pool Of
            Services?
          </h3>
          <h5 className="py-3">
            Younique is helping global businesses on their digital
            transformation journeys: one business at a time.
          </h5>
          <button
            style={{
              borderRadius: "20px 0px 20px 0px",
              background: "#FF5722",
            }}
            className="py-3 px-2 mt-5 px-3 text-white border-0"
          >
            Contact Us Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
