export const industriesFirstData = [
  {
    logo: "/images/sublogo9.png",
    title: "Technology",
    desc: "We help simplify your IT enterprise for quicker development, enable faster innovation, and enable easier scaling to right-size operations.",
  },
  {
    logo: "/images/sublogo10.png",
    title: "BFSI",
    desc: "We deploy advanced DLP and data and network security tools to help your financial institution accelerate business in the hybrid cloud era.",
  },
  {
    logo: "/images/sublogo11.png",
    title: "Healthcare",
    desc: "We deploy the latest telemedicine capabilities, AI-enabled medical device automation for your processes, and advanced security for protecting your digitized patient records.",
  },
  {
    logo: "/images/sublogo12.png",
    title: "Manufacturing",
    desc: "We help you adopt new technologies such as AI tools, data lakes, new connectivity standards, advanced analytics, and robotics; we drive automation for greater people and process efficiencies.",
  },
  {
    logo: "/images/sublogo13.png",
    title: "Education",
    desc: "We implement cloud-based technologies for secure maintenance of your institutional data, student records management, virtual classroom capabilities, digitized course material, certification, etc.",
  },
  {
    logo: "/images/sublogo14.png",
    title: "Government",
    desc: "We integrate the insights of AI and high-end cloud capabilities and help governments efficiently address problems that touch on public health, economy, security, social equity, and so on.",
  },
  {
    logo: "/images/sublogo15.png",
    title: "Hospitality",
    desc: "We help change your approach to technology, people, and processes, for better business performance and increased value to their consumers.",
  },
  {
    logo: "/images/sublogo16.png",
    title: "Retail",
    desc: "We enable business transformation with the right set of automation tools and cloud-based technologies to help you drive operational efficiencies, improve transparency in your inventory.",
  },
  {
    logo: "/images/sublogo17.png",
    title: "Telco",
    desc: "Our services help you adopt the latest digital technology solutions to replace obsolete manual processes with automation and vastly more agile technologies such as hybrid cloud.",
  },
  {
    logo: "/images/sublogo18.png",
    title: "Logistics",
    desc: "We offer fully integrated cloud-based capabilities to provide you with a seamless user experience, optimize your planning and execution, and enable uniform datasets across your supply chain.",
  },
  {
    logo: "/images/sublogo19.png",
    title: "Real Estate",
    desc: "Our cloud-based database management solutions enable you to target purchasers and tenants through digital communications so that customer traffic is then efficiently directed towards your application.",
  },
  {
    logo: "/images/sublogo20.png",
    title: "Energy",
    desc: "We assist in defining, deploying, and managing your energy transformation through modern automation applications and advanced cloud-based resource management.",
  },
  {
    logo: "/images/sublogo.21.png",
    title: "Entertainment & Media",
    desc: "We deploy the latest cloud technologies to help your business keep pace with rapidly shifting consumer demands for ever-evolving media content across a growing matrix of channels, devices, and target groups.",
  },
  {
    logo: "/images/sublogo22.png",
    title: "Digital Native Enterprises",
    desc: "We can help you accelerate toward depth vs. breadth in your approach to technology capabilities, and help you stay firmly on top of the rapid developments in cloud, data, and AI—and help prevent your business form lagging.",
  },
];
