export const firstData = [
  {
    logo: "fa-solid fa-infinity",
    title: "DevOps Services",
    desc: "Reduce complexity and shorten release cycles Of Application Delivery",
  },
  {
    logo: "fa-solid fa-cloud-arrow-up",
    title: "Migration Services",
    desc: "Modernize Your Legacy Applications, Increase Operational Excellence",
  },
  {
    logo: "fa-solid fa-folder",
    title: "Cloud BCP & DR",
    desc: "Shorten Your Recovery Curve With BCP & DR Services",
  },
  {
    logo: "fa-solid fa-shield",
    title: "Cloud Security",
    desc: "Secure Multi-Cloud: Intelligent Cloud Security Posture Management.",
  },
  {
    logo: "fa-solid fa-bolt",
    title: "Cloud Optimization",
    desc: "Building AI Optimization to Your Cloud Infrastructure and cost optimization",
  },
  {
    logo: "fa-solid fa-gears",
    title: "Cloud Managed Services",
    desc: "Certified Cloud Experts To Manage Your Cloud Infrastructure 24X7",
  },
  {
    logo: "fa-solid fa-laptop",
    title: "Virtual Desktop Infrastructure",
    desc: "Secure, Reliable, And Manageable End-User Computing Experience",
  },
  {
    logo: "fa-solid fa-chart-simple",
    title: "Data & Analytics",
    desc: "Business Strategy Through Meaningful Business Insights",
  },
];

export const secondData = [
  {
    number: "10",
    title: "Engineers and developers",
  },
  {
    number: "100",
    title: "Instances deployed",
  },
  {
    number: "100",
    title: "Customers satisfied",
  },
  {
    number: "10",
    title: "Cloud Migrations",
  },
];

export const thirdData = [
  {
    logo: "fa-solid fa-list",
    title: "Affordable Price",
    desc: "We charge based on your requiremnts, so you can choose what's best for you.",
  },
  {
    logo: "fa-solid fa-building",
    title: "Trusted Company",
    desc: "We have been delivering IT solutions For Over 30+ Years",
  },
  {
    logo: "fa-solid fa-people-group",
    title: "Dedicated Team",
    desc: "They’re not only leading our company, they’re leading the industry",
  },
  {
    logo: "fa-solid fa-cloud",
    title: "Cloud Strategic",
    desc: "Build a complete picture of your future in the cloud Computing",
  },
];

export const fourthData = [
  "https://www.valuepointsystems.com/wp-content/uploads/2021/08/aws-partner-png.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project.png",
  "https://www.valuepointsystems.com/wp-content/uploads/2021/08/New-Project-2.png",
];
