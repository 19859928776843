import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  homePageFifthData,
  homePageFourthSectionData,
  homeSectionData,
  testimonialData,
} from "./data";
import "./style.css";
import CountUp from "react-countup";
import Footer from "../Footer/Footer";
import { useGlobalContext } from "../../context";

const Home = () => {
  const { active, setActive, goToTop, openNav, setOpenNav } =
    useGlobalContext();

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Younique | Home";
  }, []);

  const handleHomeSecondSectionOnClick = (detail) => {
    console.log(detail);
    localStorage.setItem("curRoute", detail.value);
    setActive(detail.value);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleOurService = (e) => {
    console.log(e.target.value);
    localStorage.setItem("curRoute", e.target.value);
    setActive(e.target.value);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  return (
    <div className="homePage">
      <div className="homeSection d-flex justify-content-center align-items-center gap-5 text-white">
        <div className="col-lg-5 col-12 homeSectionTextWrapper">
          <h1
            className="typeWriterAnimationTextTwo text-center home-title"
            style={{ fontSize: "3.8rem" }}
          >
            Your Digital <br /> Transformation <br /> Catalyst
          </h1>
          <p className="typeWriterAnimationTextOne text-center">
            A professional approach to business results through systems
            integration
          </p>
        </div>
        {/* <div className="homeCertificateParent col-lg-5 d-none d-lg-block pe-5  text-end">
          <img
            className="homeCertificate"
            src="/images/homeCertificate.png"
            alt=""
          />
        </div> */}
      </div>
      <div className="d-flex flex-wrap justify-content-center align-items-center text-center  text-black homeSectionSecondPart pb-lg-5">
        {homeSectionData.map((detail, index) => (
          <div
            onClick={() => handleHomeSecondSectionOnClick(detail)}
            className="border homeSectionSecondPartChild mb-5 mb-lg-0 bg-white homeSecionSecondPartChildHover"
            key={index}
          >
            <Link className="text-decoration-none" to={detail.link}>
              <div className="homeSectionIconParent">
                <i
                  id="homeSectionIcons"
                  aria-hidden="true"
                  className={detail.logo}
                />
              </div>

              <h5 className="pt-4 text-black homeSectionIconsText">
                {detail.desc}
              </h5>
            </Link>
          </div>
        ))}
      </div>
      <div className="homePageThirdDiv d-lg-flex justify-content-center align-items-center gap-5 px-2 pb-5 pt-lg-5">
        <div className="col-lg-4 col-12  me-5">
          <img
            style={{ width: "100%" }}
            src="/images/Younique-Logo.png"
            className="logoAnimation"
            // src="https://www.valuepointsystems.com/wp-content/uploads/2022/10/softline-aquisition-6.gif"
            alt=""
          />
        </div>
        <div className="col-lg-6 col-12 homePageThirdDivSecondChild">
          <div style={{ position: "relative" }}>
            <p className="homePageThirdDivFirstP ps-5 ms-3">
              We Are Truly Excited To Become Part Of Younique
            </p>
          </div>

          <h3>Younique company CEO's Welcome Letter</h3>
          <p>
            Younique Info Tech was established in 2019 and is enjoying a very
            positive reputation across our business. Our reputation influences
            so many aspects of our company – the way our customers feel about
            our products and services; how regulators perceive us; and whether
            employees find Younique Info Tech a satisfying place to work.
            Therefore, it is important for all of us to protect our reputation
            through a strong culture of integrity and ethical conduct.
          </p>
          <p>
            Respect, honesty and integrity sit at the heart of our Code of
            Business Conduct. Wherever we may work and whatever our role, we are
            all responsible for upholding these principles and behaviors in
            accordance with the highest of ethical and legal standards. It is
            also important that we recognize that breaches of the Code will be
            taken seriously and may lead to disciplinary action.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Our global technology community depends on each other’s well-being
            and performance in the best of times. <br /> Achievement,
            performance and success, all lead towards and start from a simple
            point – appreciation from the people around you.
          </p>
          <p></p>
          <h6 style={{ color: "#FF5722" }}>Sagar Singh</h6>
          <h5>Founder, CEO</h5>
        </div>
      </div>
      <div className="homePageFourthDiv d-lg-flex justify-content-center align-items-center px-lg-5 px-2 gap-5 pb-5">
        {homePageFourthSectionData.map((detail, index) => (
          <div key={index}>
            <img src={detail.logo} alt="" />
            <h3 className="ps-2">{detail.title}</h3>
            <p className="ps-2" style={{ color: "#7E7E7E" }}>
              {detail.desc}
            </p>
          </div>
        ))}
      </div>
      <div className="homePageFifthDiv d-lg-flex justify-content-center  gap-5">
        <div className="col-lg-4 col-12 px-2 px-lg-0 pt-lg-5 mt-lg-5">
          <div style={{ position: "relative" }}>
            <p className="homePageThirdDivFirstP ps-5 ms-3">
              Led From The Front-Lines
            </p>
          </div>
          <h3 style={{ fontWeight: "bold" }}>
            Our Leaders, Lead The <br /> Industry
          </h3>
          <p style={{ color: "#7E7E7E" }}>
          As part of the Top 50 IT Companies and among the Top 10 Tier-II Systems Integrators based in Myanmar, we hold trust, commitment, and mutual respect as part of our core values.
          </p>
        </div>
        <div className="col-lg-6 col-12 ">
          <img
            style={{ width: "100%" }}
            src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/map-Q676QDA.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        <div style={{ position: "relative" }}>
          <p className="homePageThirdDivFirstP ps-5 ms-3 mb-0">
            So Far & Still Counting...
          </p>
        </div>
      </div>
      <div
        style={{ background: "#F9F9F9", borderRadius: "30px 0px 30px 0px" }}
        className="d-flex justify-content-center align-items-center  container py-3 flex-wrap"
      >
        {homePageFifthData.map((detail, index) => (
          <div className="col-lg-3 col-5 text-center py-5 pb-3">
            <img src={detail.logo} alt="" />
            <h1 className="pt-4">
              <CountUp start={0} end={detail.title} enableScrollSpy delay={0}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />+
                  </div>
                )}
              </CountUp>
            </h1>
            <h5 className="pt-1">{detail.desc}</h5>
          </div>
        ))}
      </div>
      <div
        style={{ position: "relative" }}
        className="d-lg-flex justify-content-center align-items-center gap-3 px-lg-5 px-3 py-5 mx-1"
      >
        <div className="ourServicePopupedDiv">
          <div style={{ position: "relative" }}>
            <h5 className="homePageThirdDivFirstP ps-5 ms-3">Our Services</h5>
          </div>
          <h3>Browse Through Our Porfolio</h3>
        </div>
        <div className="col-lg-4 col-12 p-3 text-white">
          <div
            className="border p-3"
            style={{ borderRadius: "30px 0px 30px 0px", background: "#FF5722" }}
          >
            <i
              style={{ fontSize: "2rem", color: "white" }}
              class="fa-solid fa-camera-retro"
            ></i>
            <h3 className="pt-3">CCTV Surveillance</h3>
            <p>A multi-point approach to threat preparedness</p>
            <button
              style={{ color: "#FF5722", fontWeight: "bold" }}
              className="border-0 bg-white"
            >
              Read More{" "}
              <span>
                <i
                  style={{ fontWeight: "bold" }}
                  class="fa-solid fa-arrow-right"
                ></i>
              </span>
            </button>
          </div>
        </div>
        <div className="col-lg-4 col-12 p-3 d-flex flex-column gap-4">
          <div
            style={{ borderRadius: "30px 0px 30px 0px" }}
            className="border p-3 hoverChageColor"
          >
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              className="fa-solid fa-server"
            ></i>
            <h3 className="pt-3">Data Center Modernization</h3>
            <p>Design and scale your modern data center</p>

            <Link to="./service/dataCenterModernization">
              <button
                style={{ color: "#FF5722", fontWeight: "bold" }}
                className="border-0 bg-white"
                value="Data Center Modernization"
                onClick={(e) => handleOurService(e)}
              >
                Read More{" "}
                <span>
                  <i
                    style={{ fontWeight: "bold" }}
                    class="fa-solid fa-arrow-right"
                  ></i>
                </span>
              </button>
            </Link>
          </div>
          <div
            style={{ borderRadius: "30px 0px 30px 0px" }}
            className="border p-3 hoverChageColor"
          >
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              className="fa-solid fa-network-wired"
            ></i>
            <h3 className="pt-3">Network & Infrastructure</h3>
            <p>
              Nextgen networks to the rescue with future-ready infrastructure
            </p>
            <Link to="./service/Network&Infrastructure">
              <button
                onClick={(e) => handleOurService(e)}
                value="Network & Infrastructure"
                style={{ color: "#FF5722", fontWeight: "bold" }}
                className="border-0 bg-white"
              >
                Read More{" "}
                <span>
                  <i
                    style={{ fontWeight: "bold" }}
                    class="fa-solid fa-arrow-right"
                  ></i>
                </span>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-12 py-3 px-lg-3 px-0 gap-4 d-flex flex-column ">
          <div
            style={{ borderRadius: "30px 0px 30px 0px" }}
            className="border p-3 hoverChageColor"
          >
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              className="fa-solid fa-cloud"
            ></i>
            <h3 className="pt-3">Cloud Transformation</h3>
            <p>Switch to any cloud platform with ease and flexibility</p>
            <Link to="./service/cloudTransformation">
              <button
                onClick={(e) => handleOurService(e)}
                value="Cloud Transformation"
                style={{ color: "#FF5722", fontWeight: "bold" }}
                className="border-0 bg-white"
              >
                Read More{" "}
                <span>
                  <i
                    style={{ fontWeight: "bold" }}
                    class="fa-solid fa-arrow-right"
                  ></i>
                </span>
              </button>
            </Link>
          </div>
          <div
            style={{ borderRadius: "30px 0px 30px 0px" }}
            className="border p-3 hoverChageColor"
          >
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              className="fa-solid fa-laptop"
            ></i>
            <h3 className="pt-3">Software Development</h3>
            <p>Promote collaboration with your software development</p>

            <button
              style={{ color: "#FF5722", fontWeight: "bold" }}
              className="border-0 bg-white"
            >
              Read More{" "}
              <span>
                <i
                  style={{ fontWeight: "bold" }}
                  class="fa-solid fa-arrow-right"
                ></i>
              </span>
            </button>
          </div>
          <div
            style={{ borderRadius: "30px 0px 30px 0px" }}
            className="border p-3 hoverChageColor"
          >
            <i
              style={{ fontSize: "2rem", color: "#FF5722" }}
              className="fa-brands fa-aws"
            ></i>
            <h3 className="pt-3">IT Managed Services</h3>
            <p>Improve your business outcomes with managed IT</p>
            <Link to="./service/ItManageService">
              <button
                onClick={(e) => handleOurService(e)}
                value="IT Managed Services"
                style={{ color: "#FF5722", fontWeight: "bold" }}
                className="border-0 bg-white"
              >
                Read More{" "}
                <span>
                  <i
                    style={{ fontWeight: "bold" }}
                    class="fa-solid fa-arrow-right"
                  ></i>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="firstBgFixedDiv">
        <div className="firstBgFixedDivChild">
          <div style={{ position: "relative" }}>
            <h5 className="homePageThirdDivFirstP ps-5 ms-3 pb-2">
              Testimonials
            </h5>
          </div>
          <h3>Happy Customers</h3>
        </div>
        <div className="homePageDivSecondCild d-lg-flex  align-items-center justify-content-center gap-5 text-start px-5 container">
          {testimonialData.map((detail, index) => (
            <div
              style={{
                borderRadius: "20px 0px 20px 0px",
                boxShadow: "black 0px 5px 15px",
              }}
              className="col-lg-4 col-12 mb-4 p-5 px-3 border bg-white"
            >
              <p style={{ color: "#7A7A7A" }}>{detail.desc}</p>
              <div className="d-flex justify-content-start align-items-center pt-3">
                <div className="col-2 me-3">
                  <img
                    style={{ width: "100%" }}
                    src="https://www.valuepointsystems.com/wp-content/uploads/2021/11/customer-icon-300x300.png"
                    alt=""
                  />
                </div>
                <div>
                  <h5>{detail.title}</h5>
                  <h6 style={{ color: "#7A7A7A" }}>{detail.position}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="CheersTo d-lg-flex justify-content-center align-items-center gap-5 py-lg-5"
        // style={{ marginTop: "10rem" }}
      >
        <div className="border col-lg-6 col-12 px-2 px-lg-0">
          <img
            style={{ width: "100%" }}
            src="./images/HomePage/cyber.jpg"
            alt=""
          />
        </div>
        <div className=" col-lg-4 col-12 px-2 px-lg-0 py-2 py-lg-0">
          <div style={{ position: "relative" }}>
            <h5 className="homePageThirdDivFirstP ps-5 ms-3 pb-2">
              Moving Forward...
            </h5>
          </div>
          <h2 style={{ fontWeight: "bold" }}>
            Cheers To The Next Decade Of IT Solutions
          </h2>
          <h5 style={{ color: "#7A7A7A" }} className="py-3">
            As we are beginning a new chapter, our passion for seeing your
            business grow quickly in revenue, profitability, value, customer
            experience, and our commitment to your overall business success
            remains strong.{" "}
          </h5>
          <button
            style={{
              borderRadius: "20px 0px 20px 0px",
              backgroundColor: "#FF5722",
            }}
            className="py-3 px-2 mt-5 border-0 text-white"
          >
            Thank you for staying true to us!
          </button>
        </div>
      </div>
      <div className="backgroundFixedDiv text-center">
        <div className="backgroundFixedDivUpperLayer ">
          <h3>
            Want To Know How Your Business Can Benefit From Our Deliverables?
          </h3>
          <h5 className="py-3">
            Younique Info Tech is helping global businesses on their digital
            transformation journeys: one business at a time.
          </h5>
          <button
            style={{
              borderRadius: "20px 0px 20px 0px",
              background: "#FF5722",
            }}
            className="py-3 px-2 mt-5 px-3 text-white border-0"
          >
            Contact Us Today
          </button>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
